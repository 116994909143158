// @flow
import LoadingButton from "@mui/lab/LoadingButton";
import React, { useEffect, useState } from 'react';
import * as yup from "yup";
import type {
	MessageTemplate,
	MessageTemplateType,
	MessageTemplateTypeVariable
} from "../../../types/messageTemplates/types";
import { Trans, useTranslation } from "react-i18next";
import { EXHIBITION } from "../../../locales/components/namespaces";
import { Field, FormikProvider, useFormik } from "formik";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { Alert, Checkbox, FormControl, FormControlLabel, Paper, Tab, Tabs } from "@mui/material";
import Tree from "../../general/tree/tree";
import { normalizeGreek } from "../../../helpers/normalizeGreek";
import { TabPanel } from "../../general/tabPanel/tabPanel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import WysiwygEditor from "../editor/editor";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import SaveIcon from "@mui/icons-material/Save";
import TextInput from "../textfield/textfield";
import './actionBuilder.css'
import FileUpload from "../fileUpload/fileUpload";
import TemplateTypeSelect from "./partials/templateTypeSelect";

type Props = {
	messageTemplate: MessageTemplate,
	messageTemplateTypes: Array<MessageTemplateType>,
	messageTemplateTypeVariables: Array<MessageTemplateTypeVariable>,
	onSubmit: Function,
	onChangeMessageTemplateType: Function,
	files: Array<{
		name: string,
		url: string,
		alias: string,
	}>,
	onUpdateAlias: Function,
	onFilesUpload: Function,
	onFileRemove: Function,
};

const ItemForLanguage = (props) => {
	const { t } = useTranslation(EXHIBITION)
	const { language, isSMS, files, onUpdateAlias, onFilesUpload, onFileRemove } = props
	const editorType = isSMS ? 2 : 1
	return (
		<Grid container spacing={2}>
			<Grid item xs={12} md={8} lg={9}>
				<Paper sx={{ px: 2, pb: 1 }} variant={"outlined"}>
					<Toolbar disableGutters variant={"dense"}>
						<Typography variant="h6">
							{t('Title')}
						</Typography>
					</Toolbar>
					<Box sx={{ width: '100%' }}>
						<Field
							disabled={isSMS}
							size={"small"}
							name={"title." + language}
							component={TextInput}
						/>
					</Box>
					<Toolbar disableGutters variant={"dense"}>
						<Typography variant="h6">
							{t('Text')}
						</Typography>
					</Toolbar>
					<Divider sx={{ mb: 1 }} light/>
					<Box sx={{ width: '100%' }}>
						{editorType === 1 ?
							<Field
								name={"body." + language}
								component={WysiwygEditor}
								minHeight={'520px'}
							/> :
							<Field
								size={"small"}
								name={"body." + language}
								component={TextInput}
								multiline
								minRows={16}
								maxRows={25}
							/>
						}
					</Box>
				</Paper>
			</Grid>
			{!isSMS && <Grid item xs={12} md={4} lg={3}>
				<Paper sx={{ px: 2, pb: 2 }} variant={"outlined"}>
					<Toolbar disableGutters variant={"dense"}>
						<Typography variant="h6">
							{t('Attachments')}
						</Typography>
					</Toolbar>
					<Divider sx={{ mb: 2 }} light/>
					<FileUpload
						allowAliasEdit
						files={files[language]}
						multiple={true}
						onUpdateAlias={onUpdateAlias}
						onRemove={onFileRemove}
						onFilesUpload={(files) => {
							if (files && files.length > 0) {
								files.forEach((f) => onFilesUpload(f, language))
							}
						}}
					/>
				</Paper>
			</Grid>}
		</Grid>
	)
}

const ActionBuilder = (props: Props) => {
	const {
		loadingMessageTemplate,
		messageTemplateTypeId,
		messageTemplate,
		selectedMessageTemplateType,
		messageTemplateTypes,
		messageTemplateTypeVariables,
		onSubmit,
		onChangeMessageTemplateType,
		files,
		onUpdateAlias,
		onFilesUpload,
		onFileRemove
	} = props
	
	const { t } = useTranslation(EXHIBITION)
	const [value, setValue] = useState(0)
	const handleChange = (event, newValue) => setValue(newValue)
	
	const validationSchema = yup.object({})
	const formik = useFormik({
		initialValues: {
			isActive: messageTemplate.isActive,
			title: {
				en: messageTemplate.title.en,
				el: messageTemplate.title.el
			},
			body: {
				en: messageTemplate.body.en,
				el: messageTemplate.body.el,
			}
		},
		validationSchema: validationSchema,
		onSubmit: onSubmit,
	})
	
	useEffect(() => {
		formik.setFieldValue('body', messageTemplate.body)
		formik.setFieldValue('title', messageTemplate.title)
		formik.setFieldValue('isActive', messageTemplate.isActive)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [messageTemplate])
	
	const onClick = (evt, item) => {
		const dropValue = `<%${item.id}%>`
		if (value === 0) {
			formik.setFieldValue('body.en', formik.values.body.en + dropValue)
		} else {
			formik.setFieldValue('body.el', formik.values.body.el + dropValue)
		}
	}
	
	return (
		<div className={'actionBuilder'}>
			<FormikProvider value={formik}>
				<Box component="form" onSubmit={formik.handleSubmit} sx={{ mb: 2 }}>
					<Grid sx={{ backgroundColor: 'white', padding: 1 }}>
						<Grid item xs={12}>
							{messageTemplateTypes && (
								<TemplateTypeSelect
									label={t('messageTemplateType')}
									messageTemplateTypes={messageTemplateTypes}
									messageTemplateTypeId={messageTemplateTypeId}
									onChangeMessageTemplateType={onChangeMessageTemplateType}
								/>
							)}
							<Paper sx={{mb: 1}} variant={"outlined"}>
								<Alert severity="info">
									<Trans
										i18nKey={`message_type_instructions_${selectedMessageTemplateType?.id}`}
										ns={EXHIBITION}
									/>
								</Alert>
							</Paper>
						</Grid>
						<Grid item xs={12} sm={2}>
							<Grid container spacing={1}>
								<Grid item xs={12} sm={3} lg={2}>
									<Box
										sx={{ paddingTop: '48px', backgroundColor: 'rgba(140, 158, 255, 0.08)' }}></Box>
									<Box sx={{
										paddingTop: '10px',
										paddingLeft: '4px',
										backgroundColor: 'rgba(140, 158, 255, 0.08)',
										maxHeight: '750px',
										overflowY: 'scroll',
										height: 'calc(100% - 48px)'
									}}>
										<Tree
											data={messageTemplateTypeVariables}
											onClick={onClick}
										/>
									</Box>
								</Grid>
								<Grid item xs={12} sm={9} lg={10}>
									<Stack direction="row" flexWrap={'wrap'} spacing={1} justifyContent={'space-between'} pt={1} pr={2}>
										<FormControl variant="standard">
											<FormControlLabel
												control={
													<Checkbox
														checked={formik.values.isActive}
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														size={'small'}
														name={'isActive'}
													/>
												}
												label={t('Activate Type (applies for English and Greek)')}
												labelPlacement="end"
											/>
										</FormControl>
										<LoadingButton
											loading={loadingMessageTemplate}
											size={"small"}
											type={"submit"}
											startIcon={<SaveIcon/>}
											variant="contained"
										>
											{normalizeGreek(t('Save'))}
										</LoadingButton>
									</Stack>
									<Tabs
										variant="scrollable"
										value={value}
										onChange={handleChange}
									>
										<Tab value={0} label={normalizeGreek(t('English'))}/>
										<Tab value={1} label={normalizeGreek(t('Greek'))}/>
									</Tabs>
									<TabPanel isVisible={value === 0}>
										<ItemForLanguage
											language={"en"}
											isSMS={selectedMessageTemplateType?.isSMS}
											files={files}
											onUpdateAlias={onUpdateAlias}
											onFilesUpload={onFilesUpload}
											onFileRemove={onFileRemove}
										/>
									</TabPanel>
									<TabPanel isVisible={value === 1}>
										<ItemForLanguage
											language={"el"}
											isSMS={selectedMessageTemplateType?.isSMS}
											files={files}
											onUpdateAlias={onUpdateAlias}
											onFilesUpload={onFilesUpload}
											onFileRemove={onFileRemove}
										/>
									</TabPanel>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Box>
			</FormikProvider>
		</div>
	);
};

export default ActionBuilder