import axios from "axios";
import { enqueueSnackbar } from "notistack";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { API } from "../../helpers/constants";
import { handleAxiosErrors } from "../../helpers/errorHandling";
import { EXHIBITION } from "../../locales/components/namespaces";
import useUserData from "../user/useUserData";

const useExhibitorAction = ({ exhibitionId, refreshGrid }) => {
    const { token } = useUserData()
    const { t } = useTranslation(EXHIBITION)
    const [invitations, setInvitations] = useState({
        loading: false,
        openConfirmDialog: false,
        type: 'all',
        selectionModel: [],
        recipientEmail: '',
        returnCount: false,
        confirmationMessage: null
    })
    
    const sendInvitations = useCallback((data) => {
        const { type, selectionModel, returnCount, recipientEmail } = data
        setInvitations(old => ({ ...old, loading: true, returnCount: returnCount }))
        axios({
            url: `${API}/en/exhibitor/sendinvitations`,
            method: 'post',
            data: {
                type: type,
                ExhibitionId: exhibitionId,
                ids: selectionModel,
                returnCount: returnCount,
                ...(recipientEmail ? { sendto: recipientEmail } : {})
            },
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
        }).then((response) => {
            const { mailcount } = response.data
            const message = returnCount ? '{{count}} emails will be sent in the next hours' : '{{count}} emails where created and are queued for sending. For large number of emails, this process may take several hours!'
            if (returnCount) {
                setInvitations({
                    loading: false,
                    openConfirmDialog: true,
                    type: type,
                    selectionModel: selectionModel,
                    recipientEmail: recipientEmail,
                    returnCount: false,
                    confirmationMessage: {
                        type: 'success',
                        message: message,
                        parameters: { count: mailcount }
                    }
                })
            } else {
                setInvitations({
                    loading: false,
                    openConfirmDialog: false,
                    type: 'all',
                    selectionModel: [],
                    recipientEmail: '',
                    returnCount: false,
                    confirmationMessage: null
                })
                enqueueSnackbar(t(message, { count: mailcount } ?? {}), {
                    variant: 'success',
                })
                refreshGrid()
            }
        }).catch((error) => {
            setInvitations({
                loading: false,
                openConfirmDialog: false,
                type: 'all',
                selectionModel: [],
                recipientEmail: '',
                returnCount: false,
                confirmationMessage: null
            })
            const message = error?.response?.data?.errors
            enqueueSnackbar(handleAxiosErrors(error, t(message)), {
                variant: 'error',
                autoHideDuration: null,
            })
        })
    }, [exhibitionId, refreshGrid, t, token])
    
    return {
        invitations,
        setInvitations,
        sendInvitations
    }
}

export default useExhibitorAction