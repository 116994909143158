import { fromJS } from "immutable";
import * as ActionTypes from '../../actions/types'
import { API, BulkImportStatus, EXHIBITION_IMAGE_RELATIVE_PATH } from "../../helpers/constants";
import { mapMultiple, preserveFieldsIfNotPresentInNew } from "../../helpers/map";
import { parseMultiple } from "../../helpers/parse";
import { Update, UpdateExhibitor } from "../../hooks/exhibitions/useExhibitionAction";
import {
	mapBusinessCategories,
	mapExhibition,
	mapExhibitionsGroupedHeaders,
	mapExhibitor,
	mapPartner,
	mapVisitor,
	mapVisitorReference
} from "../../types/exhibition/map";
import { parseBusinessCategory } from "../../types/exhibition/parse";

const INITIAL_STATE = {
	loading: false,
	loadingGroupedHeaders: false,
	updatingStatus: null,
	actionOnVisitorStatus: null,
	actionOnExhibitorStatus: null,
	actionOnPartnerStatus: null,
	bulkImporting: false,
	bulkImportVisitorStatus: null,
	bulkImportPartnerStatus: null,
	byId: {},
	exhibitionsGroupedHeaders: [],
	tokenById: {},
	indexTable: {
		loading: false,
		items: [],
		count: 0
	},
	visitorById: {},
	exhibitorById: {},
	exhibitorsByVisitorId: {},
	exhibitorByExhibitionId: {},
	businessCategoriesByExhibitionId: {},
	exhibitorIndexTable: {
		loading: false,
		items: [],
		count: 0
	},
	message: null,
	exhibitionsList: []
}

function setExhibitionMessage(state, action) {
	const message = action.payload
	return state
	.set('message', fromJS(message ? {
		text: message?.text, variant: message?.variant
	} : null))
}

function getExhibitions(state) {
	return state
	.setIn(['indexTable', 'loading'], true)
}

function getExhibitionsSucceeded(state, action) {
	const {payload} = action
	const mappedData = mapMultiple(payload, mapExhibition)
	const newKeys = Object.keys(mappedData).reverse()
	return state
	.set('byId', state.get('byId')
	.mergeWith(preserveFieldsIfNotPresentInNew(['exhibitors', 'welcomeMessage']), fromJS(mappedData)))
	.setIn(['indexTable', 'loading'], false)
	.setIn(['indexTable', 'count'], Number(newKeys.length))
	.setIn(['indexTable', 'items'], fromJS(newKeys))
}

function getExhibitionsFailed(state, action) {
	return state
	.set(['indexTable', 'loading'], false)
}

function getExhibitionsList(state) {
	return state
	.set('loading', true)
}

function getExhibitionsListSucceeded(state, action) {
	const {payload} = action
	const mappedData = payload.map(i => mapExhibition(i))
	return state
	.set('loading', false)
	.set('exhibitionsList', fromJS(mappedData))
}

function getExhibitionsListFailed(state, action) {
	return state
	.set('loading', false)
}

function getExhibitionsGroupedHeaders(state) {
	return state
	.set('loadingGroupedHeaders', true)
}

function getExhibitionsGroupedHeadersSucceeded(state, action) {
	const {payload} = action
	const mappedData = mapExhibitionsGroupedHeaders(payload)
	return state
	.set('loadingGroupedHeaders', false)
	.set('exhibitionsGroupedHeaders', fromJS(mappedData))
}

function getExhibitionsGroupedHeadersFailed(state, action) {
	const {message} = action.payload
	return state
	.set('loadingGroupedHeaders', false)
	.set('message', fromJS({
		text: message, variant: 'error'
	}))
}

function getExhibitionsGroupedDetails(state) {
	return state
	.set('loadingGroupedDetails', true)
}

function getExhibitionsGroupedDetailsSucceeded(state, action) {
	const {payload} = action
	const mappedData = mapMultiple(payload, mapExhibition)
	const newKeys = Object.keys(mappedData).reverse()
	return state
	.set('byId', state.get('byId')
	.mergeWith(preserveFieldsIfNotPresentInNew(['exhibitors', 'welcomeMessage']), fromJS(mappedData)))
	.set(['indexTable', 'loading'], false)
	.setIn(['indexTable', 'count'], Number(newKeys.length))
	.setIn(['indexTable', 'items'], fromJS(newKeys))
}

function getExhibitionsGroupedDetailsFailed(state, action) {
	const {message} = action.payload
	return state
	.set('loadingGroupedDetails', false)
	.set('message', fromJS({
		text: message, variant: 'error'
	}))
}

function getExhibitionDetails(state) {
	return state
	.set('loading', true);
}

function getExhibitionDetailsSucceeded(state, action) {
	const {exhibition, translations} = action.payload
	const unmappedData = {
		...exhibition[0], translations: translations
	}
	const mappedData = mapExhibition(unmappedData)
	return state
	.set('loading', false)
	.setIn(['byId', mappedData.id], fromJS(mappedData))
}

function getExhibitionDetailsFailed(state, action) {
	return state
	.set('loading', false);
}

function getExhibitorProfile(state) {
	return state
	.set('loading', true)
}

function getExhibitorProfileSucceeded(state, action) {
	const {
		payload: {exhibitor, exhibitorBusinessCategs, visitors, partners, Brands},
		parameters: {id, exhibitionId}
	} = action
	const unmappedData = {
		...exhibitor[0],
		businessCategories: exhibitorBusinessCategs,
		visitors: visitors,
		partners: partners,
		brands: Brands
	}
	const mappedData = mapExhibitor(unmappedData)
	return state
	.set('loading', false)
	.setIn(['exhibitorByExhibitionId', exhibitionId, id], mappedData.id)
	.setIn(['exhibitorById', mappedData.id], fromJS(mappedData))
}

function getExhibitorProfileFailed(state, action) {
	return state
	.set('loading', false)
}

function setExhibitorEditMode(state, action) {
	const {exhibitorId, editMode} = action.payload
	return state
	.set('loading', false)
	.setIn(['exhibitorById', exhibitorId, 'editMode'], editMode)
}

function getVisitorProfile(state) {
	return state
	.set('loading', true)
}

function getVisitorProfileSucceeded(state, action) {
	const {visitor, businessCategs, address, interests} = action.payload
	const unMappedData = {
		...visitor[0], businessCategories: businessCategs, address, interests: interests
	}
	const mappedData = mapVisitor(unMappedData)
	return state
	.setIn(['visitorById', mappedData.id], fromJS(mappedData))
	.set('loading', false);
}

function getVisitorProfileFailed(state, action) {
	return state
	.set('loading', false);
}

function getVisitorExhibitors(state) {
	return state
	.set('loading', true)
}

function getVisitorExhibitorsSucceeded(state, action) {
	const {payload, parameters: {id}} = action
	const mappedData = mapMultiple(payload, mapVisitorReference)
	return state
	.set('loading', false)
	.setIn(['exhibitorsByVisitorId', id], fromJS(mappedData))
}

function getVisitorExhibitorsFailed(state, action) {
	return state
	.set('loading', false)
}

function updateExhibition(state, action) {
	const {id, updateType} = action.payload
	switch (updateType) {
		case Update.PreregistrationForm: {
			const {PreRegForm} = action.payload
			return state
			.set('loading', true)
			.set('updatingStatus', null)
			.setIn(['byId', id, 'preregistrationFormFields'], fromJS(JSON.parse(PreRegForm)))
		}
		case Update.PreregistrationFormEnabled: {
			const {isPreregistration} = action.payload
			return state
			.set('loading', true)
			.set('updatingStatus', null)
			.setIn(['byId', id, 'preregistrationEnabled'], isPreregistration)
		}
		case Update.PreregistrationNeedsApproval: {
			const {isApproveRegistration} = action.payload
			return state
			.set('loading', true)
			.set('updatingStatus', null)
			.setIn(['byId', id, 'preregistrationNeedsApproval'], isApproveRegistration)
		}
		case Update.WelcomePage: {
			const {WelcomeTextEN, WelcomeTextEL} = action.payload
			return state
			.set('loading', true)
			.set('updatingStatus', null)
			.setIn(['byId', id, 'welcomeMessage', 'en'], WelcomeTextEN)
			.setIn(['byId', id, 'welcomeMessage', 'el'], WelcomeTextEL)
		}
		case Update.DaysForCatalogSubmission: {
			const {DaysForCatalogSubmission} = action.payload
			return state
			.set('loading', true)
			.set('updatingStatus', null)
			.setIn(['byId', id, 'daysForCatalogSubmission'], DaysForCatalogSubmission)
		}
		case Update.ExhibitorVisitors: {
			const {ExhibitorVisitors} = action.payload
			return state
			.set('loading', true)
			.set('updatingStatus', null)
			.setIn(['byId', id, 'displayVisitorsToExhibitor'], ExhibitorVisitors)
		}
		case Update.ExhibitorKitAndAds: {
			const {ExhibitorKitAndAds} = action.payload
			return state
			.set('loading', true)
			.set('updatingStatus', null)
			.setIn(['byId', id, 'displayExhibitorKitAndAddsToExhibitor'], ExhibitorKitAndAds)
		}
		case Update.ExhibitorPartners: {
			const {ExhibitorPartners} = action.payload
			return state
			.set('loading', true)
			.set('updatingStatus', null)
			.setIn(['byId', id, 'displayPartnersToExhibitor'], ExhibitorPartners)
		}
		case Update.ExhibitorInvitesVisitorsWithBarcode: {
			const {ExhibitorVisitorBarcode} = action.payload
			return state
			.set('loading', true)
			.set('updatingStatus', null)
			.setIn(['byId', id, 'exhibitorInvitesVisitorsWithBarcode'], ExhibitorVisitorBarcode)
		}
		case Update.DisableRegistrationMessages: {
			const {DisableRegistrationMessages} = action.payload
			return state
			.set('loading', true)
			.set('updatingStatus', null)
			.setIn(['byId', id, 'disableRegistrationMessages'], DisableRegistrationMessages)
		}
		case Update.PreregistrationHasFieldGroups: {
			const {PreregistrationHasFieldGroups} = action.payload
			return state
			.set('loading', true)
			.set('updatingStatus', null)
			.setIn(['byId', id, 'preregistrationHasFieldGroups'], PreregistrationHasFieldGroups)
		}
		case Update.CatalogueLanguage: {
			const {CatalogueLanguage} = action.payload
			return state
			.set('loading', true)
			.set('updatingStatus', null)
			.setIn(['byId', id, 'catalogueLanguage'], CatalogueLanguage)
		}
		case Update.PreregistrationRedirect: {
			const {PreregistrationRedirect} = action.payload
			return state
			.set('loading', true)
			.set('updatingStatus', null)
			.setIn(['byId', id, 'preregistrationRedirect'], PreregistrationRedirect)
		}
		case Update.PreregistrationHeaderImageLink: {
			const {PreregistrationHeaderImageLink} = action.payload
			return state
			.set('loading', true)
			.set('updatingStatus', null)
			.setIn(['byId', id, 'preregistrationHeaderImageLink'], PreregistrationHeaderImageLink)
		}
		case Update.GoogleTagCode: {
			const {GoogleTagCode} = action.payload
			return state
			.set('loading', true)
			.set('updatingStatus', null)
			.setIn(['byId', id, 'googleTagCode'], GoogleTagCode)
		}
		case Update.SendSmsName: {
			const {SendSmsName} = action.payload
			return state
			.set('loading', true)
			.set('updatingStatus', null)
			.setIn(['byId', id, 'sendSmsName'], SendSmsName)
		}
		case Update.SendFromName: {
			const {SendFromName} = action.payload
			return state
			.set('loading', true)
			.set('updatingStatus', null)
			.setIn(['byId', id, 'sendFromName'], SendFromName)
		}
		case Update.SendFromEmail: {
			const {SendFromEmail} = action.payload
			return state
			.set('loading', true)
			.set('updatingStatus', null)
			.setIn(['byId', id, 'sendFromEmail'], SendFromEmail)
		}
		case Update.VisitorCategoryRequired: {
			const {VisitorCategoryRequired} = action.payload
			return state
			.set('loading', true)
			.set('updatingStatus', null)
			.setIn(['byId', id, 'visitorCategoryRequiredInImport'], VisitorCategoryRequired)
		}
		case Update.NumberOfPartnersPerExhibitor: {
			const {PartnerNum} = action.payload
			return state
			.set('loading', true)
			.set('updatingStatus', null)
			.setIn(['byId', id, 'numberOfPartnersPerExhibitor'], PartnerNum)
		}
		case Update.CatalogLocked: {
			const {isProfileLocked} = action.payload
			return state
			.set('loading', true)
			.set('updatingStatus', null)
			.setIn(['byId', id, 'catalogLocked'], isProfileLocked)
		}
		case Update.CatalogEnableKioskSign: {
			const {CatalogEnableKioskSign} = action.payload
			return state
			.set('loading', true)
			.set('updatingStatus', null)
			.setIn(['byId', id, 'catalogEnableKioskSign'], CatalogEnableKioskSign)
		}
		case Update.CatalogueProfileLength: {
			const {CatalogueProfileLength} = action.payload
			return state
			.set('loading', true)
			.set('updatingStatus', null)
			.setIn(['byId', id, 'catalogueProfileLength'], CatalogueProfileLength)
		}
		case Update.ExhibitorServiceProviders: {
			const {ExhibitorServiceProviders} = action.payload
			return state
			.set('loading', true)
			.set('updatingStatus', null)
			.setIn(['byId', id, 'displayServiceProvidersToExhibitor'], ExhibitorServiceProviders)
		}
		case Update.hasExhibitorBrands: {
			const {hasExhibitorBrands} = action.payload
			return state
			.set('loading', true)
			.set('updatingStatus', null)
			.setIn(['byId', id, 'hasExhibitorBrands'], hasExhibitorBrands)
		}
		case Update.PreregistrationCountry: {
			const {PreregistrationCountry} = action.payload
			return state
			.set('loading', true)
			.set('updatingStatus', null)
			.setIn(['byId', id, 'preregistrationCountry'], PreregistrationCountry)
		}
		case Update.PreregistrationApprove: {
			const {PreregistrationApprove} = action.payload
			return state
			.set('loading', true)
			.set('updatingStatus', null)
			.setIn(['byId', id, 'preregistrationApprovePerCountry'], PreregistrationApprove)
		}
		case Update.isKioskSignEditable: {
			const {isKioskSignEditable} = action.payload
			return state
			.set('loading', true)
			.set('updatingStatus', null)
			.setIn(['byId', id, 'isKioskSignEditable'], isKioskSignEditable)
		}
		case Update.RegistrationStartDays: {
			const {RegistrationStartDays} = action.payload
			return state
			.set('loading', true)
			.set('updatingStatus', null)
			.setIn(['byId', id, 'registrationStartDays'], RegistrationStartDays)
		}
		case Update.RegistrationEndDays: {
			const {RegistrationEndDays} = action.payload
			return state
			.set('loading', true)
			.set('updatingStatus', null)
			.setIn(['byId', id, 'registrationEndDays'], RegistrationEndDays)
		}
		case Update.ValidQrDays: {
			const {ValidQrDays} = action.payload
			return state
			.set('loading', true)
			.set('updatingStatus', null)
			.setIn(['byId', id, 'validQrDays'], ValidQrDays)
		}
		case Update.DaysForCatalogNotification: {
			const {DaysForCatalogNotification} = action.payload
			return state
			.set('loading', true)
			.set('updatingStatus', null)
			.setIn(['byId', id, 'daysForCatalogNotification'], DaysForCatalogNotification)
		}
		case Update.ButtonColor: {
			const { ButtonColor } = action.payload
			return state
			.set('loading', true)
			.set('updatingStatus', null)
			.setIn(['byId', id, 'buttonColor'], ButtonColor)
		}
		default: {
			return state
			.set('loading', true)
			.set('updatingStatus', null)
		}
	}
}

function updateExhibitionSucceeded(state) {
	return state
	.set('loading', false)
	.set('updatingStatus', fromJS({type: 'success', message: 'Exhibition updated successfully'}))
}

function clearUpdateExhibitionStatus(state, action) {
	return state
	.set('updatingStatus', null)
}

function clearActionOnVisitorStatus(state) {
	return state
	.set('actionOnVisitorStatus', null)
}

function clearActionOnExhibitorStatus(state) {
	return state
	.set('actionOnExhibitorStatus', null)
}

function actionOnVisitor(state) {
	return state
	.set('loading', true)
	.set('actionOnVisitorStatus', null)
}

function actionOnVisitorFailed(state, action) {
	const {message} = action.payload
	
	return state
	.set('loading', false)
	.set('actionOnVisitorStatus', fromJS({
		type: 'error',
		message: action.type === 'DELETE_MULTIPLE_VISITORS_FAILED' ? 'One or more selected visitors have already been scanned and cannot be deleted!' : message
	}))
}

function createVisitorSucceeded(state, action) {
	const {payload: {visitor, businessCategs, address, interests}, parameters} = action
	const {ExhibitionId, ExhibitorId} = Object.fromEntries(parameters)
	const unMappedData = {
		...visitor[0], businessCategories: businessCategs, address, interests: interests
	}
	const mappedData = mapVisitor(unMappedData)
	return state
	.setIn(['visitorById', mappedData.id], fromJS(mappedData))
	.setIn(['exhibitorById', ExhibitorId, 'visitors', mappedData.id], fromJS(mappedData))
	.setIn(['byId', ExhibitionId, 'visitors', mappedData.id], fromJS(mappedData))
	.set('loading', false)
	.set('actionOnVisitorStatus', fromJS({
		type: 'success',
		message: 'Visitor updated successfully',
		parameters: mappedData
	}))
}

function updateVisitorSucceeded(state, action) {
	const {payload: {visitor, businessCategs, address, interests}, parameters} = action
	const {ExhibitionId, ExhibitorId} = Object.fromEntries(parameters)
	const unMappedData = {
		...visitor[0], businessCategories: businessCategs, address, interests: interests
	}
	const mappedData = mapVisitor(unMappedData)
	return state
	.setIn(['visitorById', mappedData.id], fromJS(mappedData))
	.setIn(['exhibitorById', ExhibitorId, 'visitors', mappedData.id], fromJS(mappedData))
	.setIn(['byId', ExhibitionId, 'visitors', mappedData.id], fromJS(mappedData))
	.set('loading', false)
	.set('actionOnVisitorStatus', fromJS({type: 'success', message: 'Visitor updated successfully'}))
}

function clearBulkImportVisitorStatus(state) {
	return state
	.set('bulkImportVisitorStatus', null)
}

function createBulkVisitors(state) {
	return state
	.set('bulkImporting', true)
	.set('bulkImportVisitorStatus', fromJS(null))
}

function createBulkVisitorsSucceeded(state, action) {
	const {errors, ids, ImportNum} = action.payload
	const hasSuccess = ids && ids.length > 0
	const hasErrors = errors && errors.length > 0
	let statusCode = (hasSuccess && !hasErrors) ? BulkImportStatus.AllSuccessful : (!hasSuccess && hasErrors) ? BulkImportStatus.AllErrors : (hasSuccess && hasErrors) ? BulkImportStatus.SucceededWithErrors : BulkImportStatus.NothingToDo
	return state
	.set('bulkImporting', false)
	.set('bulkImportVisitorStatus', fromJS({
		statusCode: statusCode, newItems: ids, errors: errors, importNum: ImportNum
	}))
}

function createBulkVisitorsFailed(state, action) {
	const {message} = action.payload
	return state
	.set('bulkImporting', false)
	.set('bulkImportVisitorStatus', fromJS({statusCode: BulkImportStatus.AllErrors, errors: [message]}))
}

function clearActionOnPartnerStatus(state) {
	return state
	.set('actionOnPartnerStatus', null)
}

function actionOnPartner(state) {
	return state
	.set('loading', true)
	.set('actionOnPartnerStatus', null)
}

function actionOnPartnerFailed(state, action) {
	const {message} = action.payload
	return state
	.set('loading', false)
	.set('actionOnPartnerStatus', fromJS({type: 'error', message: message}))
}

function createPartnerSucceeded(state, action) {
	const {payload, parameters: {ExhibitorId}} = action
	const mappedData = mapPartner(payload)
	return state
	.setIn(['partnerById', mappedData.id], fromJS(mappedData))
	.setIn(['exhibitorById', ExhibitorId, 'partners', mappedData.id], fromJS(mappedData))
	.set('loading', false)
	.set('actionOnPartnerStatus', fromJS({type: 'success', message: 'Added successfully'}))
}

function removePartnerSucceeded(state, action) {
	const {parameters: {id, ExhibitorId}} = action
	return state
	.removeIn(['partnerById', id])
	.removeIn(['exhibitorById', ExhibitorId, 'partners', id])
	.set('loading', false)
	.set('actionOnPartnerStatus', fromJS({type: 'success', message: 'Partner removed successfully'}))
}

function sendPartnerBadgesSucceeded(state, action) {
	const {mailcount} = action.payload
	return state
	.set('loading', false)
	.set('actionOnPartnerStatus', fromJS({
		type: 'success',
		message: 'Sent {{count}} emails  successfully',
		parameters: {count: mailcount}
	}))
}

function clearBulkImportPartnerStatus(state) {
	return state
	.set('bulkImportPartnerStatus', null)
}

function createBulkPartners(state) {
	return state
	.set('bulkImporting', true)
	.set('bulkImportPartnerStatus', fromJS(null))
}

function createBulkPartnersSucceeded(state, action) {
	const {errors, ids} = action.payload
	const hasSuccess = ids && ids.length > 0
	const hasErrors = errors && errors.length > 0
	let statusCode = (hasSuccess && !hasErrors) ? BulkImportStatus.AllSuccessful :
		(!hasSuccess && hasErrors) ? BulkImportStatus.AllErrors :
			(hasSuccess && hasErrors) ? BulkImportStatus.SucceededWithErrors :
				BulkImportStatus.NothingToDo
	return state
	.set('bulkImporting', false)
	.set('bulkImportPartnerStatus', fromJS({
		statusCode: statusCode, newItems: ids, errors: errors
	}))
}

function createBulkPartnersFailed(state, action) {
	const {message} = action.payload
	return state
	.set('bulkImporting', false)
	.set('bulkImportPartnerStatus', fromJS({status: BulkImportStatus.AllErrors, message: message}))
}

function updateExhibitorProfile(state, action) {
	const {updateType, exhibitorId, id: ExhibitionId} = action.payload
	switch (updateType) {
		case UpdateExhibitor.Catalog: {
			const {
				addr1AddressEL,
				addr1CityEL,
				addr1ZipCodeEL,
				addr1AddrPhoneEL,
				addr1AddressEN,
				addr1CityEN,
				addr1ZipCodeEN,
				addr1AddrPhoneEN,
				addr2AddressEL,
				addr2CityEL,
				addr2ZipCodeEL,
				addr2AddrPhoneEL,
				addr2AddressEN,
				addr2CityEN,
				addr2ZipCodeEN,
				addr2AddrPhoneEN,
				ExhibitorEmail,
				CatalogProfileEN,
				CatalogProfileEL,
				CatalogBrandEL,
				CatalogBrandEN,
				Website,
				Facebook,
				Instagram,
				LinkedIn
			} = action.payload
			return state
			.set('loading', true)
			.set('updatingStatus', null)
			.setIn(['exhibitorById', exhibitorId, 'address1', 'street', 'en'], addr1AddressEN)
			.setIn(['exhibitorById', exhibitorId, 'address1', 'street', 'el'], addr1AddressEL)
			.setIn(['exhibitorById', exhibitorId, 'address1', 'postalCode', 'en'], addr1ZipCodeEN)
			.setIn(['exhibitorById', exhibitorId, 'address1', 'postalCode', 'el'], addr1ZipCodeEL)
			.setIn(['exhibitorById', exhibitorId, 'address1', 'city', 'en'], addr1CityEN)
			.setIn(['exhibitorById', exhibitorId, 'address1', 'city', 'el'], addr1CityEL)
			.setIn(['exhibitorById', exhibitorId, 'address1', 'phone', 'en'], addr1AddrPhoneEN)
			.setIn(['exhibitorById', exhibitorId, 'address1', 'phone', 'el'], addr1AddrPhoneEL)
			
			.setIn(['exhibitorById', exhibitorId, 'address2', 'street', 'en'], addr2AddressEN)
			.setIn(['exhibitorById', exhibitorId, 'address2', 'street', 'el'], addr2AddressEL)
			.setIn(['exhibitorById', exhibitorId, 'address2', 'postalCode', 'en'], addr2ZipCodeEN)
			.setIn(['exhibitorById', exhibitorId, 'address2', 'postalCode', 'el'], addr2ZipCodeEL)
			.setIn(['exhibitorById', exhibitorId, 'address2', 'city', 'en'], addr2CityEN)
			.setIn(['exhibitorById', exhibitorId, 'address2', 'city', 'el'], addr2CityEL)
			.setIn(['exhibitorById', exhibitorId, 'address2', 'phone', 'en'], addr2AddrPhoneEN)
			.setIn(['exhibitorById', exhibitorId, 'address2', 'phone', 'el'], addr2AddrPhoneEL)
			
			.setIn(['exhibitorById', exhibitorId, 'catalog', 'profile', 'en'], CatalogProfileEN)
			.setIn(['exhibitorById', exhibitorId, 'catalog', 'profile', 'el'], CatalogProfileEL)
			.setIn(['exhibitorById', exhibitorId, 'catalog', 'brand', 'en'], CatalogBrandEN)
			.setIn(['exhibitorById', exhibitorId, 'catalog', 'brand', 'el'], CatalogBrandEL)
			.setIn(['exhibitorById', exhibitorId, 'social', 'fb'], Facebook)
			.setIn(['exhibitorById', exhibitorId, 'social', 'instagram'], Instagram)
			.setIn(['exhibitorById', exhibitorId, 'social', 'linkedIn'], LinkedIn)
			.setIn(['exhibitorById', exhibitorId, 'website'], Website)
			.setIn(['exhibitorById', exhibitorId, 'email'], ExhibitorEmail)
		}
		case UpdateExhibitor.BusinessCategories: {
			const {BusinessCategs} = action.payload
			const allBusinessCategories = parseMultiple(state.getIn(['businessCategoriesByExhibitionId', ExhibitionId]), (i) => parseBusinessCategory(i, 'any'))
			const newValue = allBusinessCategories.filter(i => BusinessCategs.indexOf(i.id) > -1)
			return state
			.set('loading', true)
			.setIn(['exhibitorById', exhibitorId, 'businessCategories'], fromJS(newValue))
		}
		default:
			return state
			.set('loading', true)
			.set('updatingStatus', null)
	}
}

function updateExhibitorProfileSucceeded(state, action) {
	const {exhibitorId} = action.parameters
	return state
	.set('loading', false)
	.setIn(['exhibitorById', exhibitorId, 'editMode'], false)
	.set('updatingStatus', fromJS({type: 'success', message: 'Profile updated successfully'}))
}

function exhibitorCatalogueConfirm(state, action) {
	return state
	.set('loading', false)
	.set('updatingStatus', null)
}

function exhibitorCatalogueConfirmSucceeded(state, action) {
	const {exhibitorId} = action.parameters
	return state
	.set('loading', false)
	.set('updatingStatus', fromJS({type: 'success', message: 'Catalogue confirmed successfully'}))
	.setIn(['exhibitorById', exhibitorId, 'confirmedCatalogue'], true)
}

function exhibitorCatalogueConfirmFailed(state, action) {
	return state
	.set('loading', false)
	.set('updatingStatus', fromJS({type: 'success', message: 'Catalogue confirmation failed'}))
}

function exhibitorCatalogueConfirmReset(state, action) {
	return state
	.set('loading', false)
	.set('updatingStatus', null)
}

function exhibitorCatalogueConfirmResetSucceeded(state, action) {
	const {exhibitorId} = action.parameters
	return state
	.set('loading', false)
	.set('updatingStatus', fromJS({type: 'success', message: 'Catalogue confirmation reset successful'}))
	.setIn(['exhibitorById', exhibitorId, 'confirmedCatalogue'], false)
}

function exhibitorCatalogueConfirmResetFailed(state, action) {
	return state
	.set('loading', false)
	.set('updatingStatus', fromJS({type: 'success', message: 'Catalogue confirmation reset failed'}))
}

function getExhibitionBusinessCategories(state) {
	return state
	.set('loading', true)
}

function getExhibitionBusinessCategoriesSucceeded(state, action) {
	const {payload, parameters: {id}} = action
	return state
	.set('loading', false)
	.setIn(['businessCategoriesByExhibitionId', id], fromJS(mapBusinessCategories(payload)))
}

function getExhibitionBusinessCategoriesFailed(state, action) {
	return state
	.set('loading', false)
}

function updateInit(state) {
	return state
	.set('loading', true)
	.set('updatingStatus', null)
}

function updateFailed(state, action) {
	const {message} = action.payload
	return state
	.set('loading', false)
	.set('updatingStatus', fromJS({type: 'error', message: message}))
}

function updateQuickPrintFooterImage(state, action) {
	return state
	.set('loading', true)
	.set('updatingStatus', null)
}

function updateQuickPrintFooterImageSucceeded(state, action) {
	const {id, language} = action.parameters
	const {Logo} = action.payload
	const lang = language === 'EL' ? 'el' : 'en'
	const image = API + EXHIBITION_IMAGE_RELATIVE_PATH + Logo
	return state
	.set('loading', false)
	.setIn(['byId', id, 'quickPrintFooterImage', lang], image)
	.set('updatingStatus', fromJS({type: 'success', message: 'Footer image uploaded successfully!'}))
}

function updateQuickPrintFooterImageFailed(state, action) {
	const {message} = action.payload
	return state
	.set('loading', false)
	.set('updatingStatus', fromJS({type: 'error', message: message}))
}

function deleteQuickPrintFooterImage(state, action) {
	return state
	.set('loading', true)
	.set('updatingStatus', null)
}

function deleteQuickPrintFooterImageSucceeded(state, action) {
	return state
	.set('loading', false)
	.set('updatingStatus', fromJS({type: 'success', message: 'Footer image removed successfully!'}))
}

function deleteQuickPrintFooterImageFailed(state, action) {
	const {message} = action.payload
	return state
	.set('loading', false)
	.set('updatingStatus', fromJS({type: 'error', message: message}))
}

function copyExhibitionSettings(state, action) {
	return state
	.set('loading', true)
}

function copyExhibitionSettingsSuccedded(state, action) {
	return state
	.set('loading', false)
	.set('updatingStatus', fromJS({type: 'success', message: 'Exhibition settings copied successfully!'}))
}

function copyExhibitionSettingsFailed(state, action) {
	const {message} = action.payload
	return state
	.set('loading', false)
	.set('updatingStatus', fromJS({type: 'error', message: message}))
}

const exhibition = {
	initialState: INITIAL_STATE, handlers: {
		
		[ActionTypes.GET_EXHIBITIONS]: getExhibitions,
		[ActionTypes.GET_EXHIBITIONS_SUCCEEDED]: getExhibitionsSucceeded,
		[ActionTypes.GET_EXHIBITIONS_FAILED]: getExhibitionsFailed,
		
		[ActionTypes.GET_EXHIBITIONS_LIST]: getExhibitionsList,
		[ActionTypes.GET_EXHIBITIONS_LIST_SUCCEEDED]: getExhibitionsListSucceeded,
		[ActionTypes.GET_EXHIBITIONS_LIST_FAILED]: getExhibitionsListFailed,
		
		[ActionTypes.GET_EXHIBITION_DETAILS]: getExhibitionDetails,
		[ActionTypes.GET_EXHIBITION_DETAILS_SUCCEEDED]: getExhibitionDetailsSucceeded,
		[ActionTypes.GET_EXHIBITION_DETAILS_FAILED]: getExhibitionDetailsFailed,
		
		[ActionTypes.GET_EXHIBITION_DETAILS_PUBLIC]: getExhibitionDetails,
		[ActionTypes.GET_EXHIBITION_DETAILS_PUBLIC_SUCCEEDED]: getExhibitionDetailsSucceeded,
		[ActionTypes.GET_EXHIBITION_DETAILS_PUBLIC_FAILED]: getExhibitionDetailsFailed,
		
		[ActionTypes.GET_EXHIBITOR_PROFILE]: getExhibitorProfile,
		[ActionTypes.GET_EXHIBITOR_PROFILE_SUCCEEDED]: getExhibitorProfileSucceeded,
		[ActionTypes.GET_EXHIBITOR_PROFILE_FAILED]: getExhibitorProfileFailed,
		
		[ActionTypes.GET_VISITOR_PROFILE]: getVisitorProfile,
		[ActionTypes.GET_VISITOR_PROFILE_SUCCEEDED]: getVisitorProfileSucceeded,
		[ActionTypes.GET_VISITOR_PROFILE_FAILED]: getVisitorProfileFailed,
		
		[ActionTypes.GET_VISITOR_EXHIBITORS]: getVisitorExhibitors,
		[ActionTypes.GET_VISITOR_EXHIBITORS_SUCCEEDED]: getVisitorExhibitorsSucceeded,
		[ActionTypes.GET_VISITOR_EXHIBITORS_FAILED]: getVisitorExhibitorsFailed,
		
		[ActionTypes.CLEAR_UPDATE_EXHIBITION_STATUS]: clearUpdateExhibitionStatus,
		[ActionTypes.UPDATE_EXHIBITION]: updateExhibition,
		[ActionTypes.UPDATE_EXHIBITION_SUCCEEDED]: updateExhibitionSucceeded,
		[ActionTypes.UPDATE_EXHIBITION_FAILED]: updateFailed,
		[ActionTypes.UPDATE_BADGE_CONFIGURATION]: updateInit,
		[ActionTypes.UPDATE_BADGE_CONFIGURATION_SUCCEEDED]: updateExhibitionSucceeded,
		[ActionTypes.UPDATE_BADGE_CONFIGURATION_FAILED]: updateFailed,
		
		[ActionTypes.CLEAR_ACTION_ON_VISITOR_STATUS]: clearActionOnVisitorStatus,
		[ActionTypes.CLEAR_ACTION_ON_EXHIBITOR_STATUS]: clearActionOnExhibitorStatus,
		[ActionTypes.CREATE_VISITOR]: actionOnVisitor,
		[ActionTypes.CREATE_VISITOR_SUCCEEDED]: createVisitorSucceeded,
		[ActionTypes.CREATE_VISITOR_FAILED]: actionOnVisitorFailed,
		[ActionTypes.UPDATE_VISITOR]: actionOnVisitor,
		[ActionTypes.UPDATE_VISITOR_SUCCEEDED]: updateVisitorSucceeded,
		[ActionTypes.UPDATE_VISITOR_FAILED]: actionOnVisitorFailed,
		
		[ActionTypes.CLEAR_BULK_VISITORS_STATUS]: clearBulkImportVisitorStatus,
		[ActionTypes.CREATE_BULK_VISITORS]: createBulkVisitors,
		[ActionTypes.CREATE_BULK_VISITORS_SUCCEEDED]: createBulkVisitorsSucceeded,
		[ActionTypes.CREATE_BULK_VISITORS_FAILED]: createBulkVisitorsFailed,
		[ActionTypes.CREATE_BULK_VISITORS_FOR_BADGES]: createBulkVisitors,
		[ActionTypes.CREATE_BULK_VISITORS_FOR_BADGES_SUCCEEDED]: createBulkVisitorsSucceeded,
		[ActionTypes.CREATE_BULK_VISITORS_FOR_BADGES_FAILED]: createBulkVisitorsFailed,
		
		[ActionTypes.CLEAR_ACTION_ON_PARTNER_STATUS]: clearActionOnPartnerStatus,
		[ActionTypes.CREATE_PARTNER]: actionOnPartner,
		[ActionTypes.CREATE_PARTNER_SUCCEEDED]: createPartnerSucceeded,
		[ActionTypes.CREATE_PARTNER_FAILED]: actionOnPartnerFailed,
		[ActionTypes.REMOVE_PARTNER]: actionOnPartner,
		[ActionTypes.REMOVE_PARTNER_SUCCEEDED]: removePartnerSucceeded,
		[ActionTypes.REMOVE_PARTNER_FAILED]: actionOnPartnerFailed,
		[ActionTypes.SEND_PARTNER_BADGES]: actionOnPartner,
		[ActionTypes.SEND_PARTNER_BADGES_SUCCEEDED]: sendPartnerBadgesSucceeded,
		[ActionTypes.SEND_PARTNER_BADGES_FAILED]: actionOnPartnerFailed,
		
		[ActionTypes.CLEAR_BULK_PARTNERS_STATUS]: clearBulkImportPartnerStatus,
		[ActionTypes.CREATE_BULK_PARTNERS]: createBulkPartners,
		[ActionTypes.CREATE_BULK_PARTNERS_SUCCEEDED]: createBulkPartnersSucceeded,
		[ActionTypes.CREATE_BULK_PARTNERS_FAILED]: createBulkPartnersFailed,
		
		[ActionTypes.UPDATE_EXHIBITOR_PROFILE]: updateExhibitorProfile,
		[ActionTypes.UPDATE_EXHIBITOR_PROFILE_SUCCEEDED]: updateExhibitorProfileSucceeded,
		[ActionTypes.UPDATE_EXHIBITOR_PROFILE_FAILED]: updateFailed,
		
		[ActionTypes.GET_EXHIBITION_BUSINESS_CATEGORIES]: getExhibitionBusinessCategories,
		[ActionTypes.GET_EXHIBITION_BUSINESS_CATEGORIES_SUCCEEDED]: getExhibitionBusinessCategoriesSucceeded,
		[ActionTypes.GET_EXHIBITION_BUSINESS_CATEGORIES_FAILED]: getExhibitionBusinessCategoriesFailed,
		
		[ActionTypes.EXHIBITOR_CATALOGUE_CONFIRM]: exhibitorCatalogueConfirm,
		[ActionTypes.EXHIBITOR_CATALOGUE_CONFIRM_SUCCEEDED]: exhibitorCatalogueConfirmSucceeded,
		[ActionTypes.EXHIBITOR_CATALOGUE_CONFIRM_FAILED]: exhibitorCatalogueConfirmFailed,
		
		[ActionTypes.EXHIBITOR_CATALOGUE_CONFIRM_RESET]: exhibitorCatalogueConfirmReset,
		[ActionTypes.EXHIBITOR_CATALOGUE_CONFIRM_RESET_SUCCEEDED]: exhibitorCatalogueConfirmResetSucceeded,
		[ActionTypes.EXHIBITOR_CATALOGUE_CONFIRM_RESET_FAILED]: exhibitorCatalogueConfirmResetFailed,
		
		[ActionTypes.SET_EXHIBITOR_EDIT_MODE]: setExhibitorEditMode,
		
		[ActionTypes.UPDATE_QUICK_PRINT_FOOTER_IMAGE]: updateQuickPrintFooterImage,
		[ActionTypes.UPDATE_QUICK_PRINT_FOOTER_IMAGE_SUCCEEDED]: updateQuickPrintFooterImageSucceeded,
		[ActionTypes.UPDATE_QUICK_PRINT_FOOTER_IMAGE_FAILED]: updateQuickPrintFooterImageFailed,
		
		[ActionTypes.DELETE_QUICK_PRINT_FOOTER_IMAGE]: deleteQuickPrintFooterImage,
		[ActionTypes.DELETE_QUICK_PRINT_FOOTER_IMAGE_SUCCEEDED]: deleteQuickPrintFooterImageSucceeded,
		[ActionTypes.DELETE_QUICK_PRINT_FOOTER_IMAGE_FAILED]: deleteQuickPrintFooterImageFailed,
		
		[ActionTypes.SET_EXHIBITION_MESSAGE]: setExhibitionMessage,
		[ActionTypes.GET_EXHIBITIONS_GROUPED_HEADERS]: getExhibitionsGroupedHeaders,
		[ActionTypes.GET_EXHIBITIONS_GROUPED_HEADERS_SUCCEEDED]: getExhibitionsGroupedHeadersSucceeded,
		[ActionTypes.GET_EXHIBITIONS_GROUPED_HEADERS_FAILED]: getExhibitionsGroupedHeadersFailed,
		
		[ActionTypes.GET_EXHIBITIONS_GROUPED_DETAILS]: getExhibitionsGroupedDetails,
		[ActionTypes.GET_EXHIBITIONS_GROUPED_DETAILS_SUCCEEDED]: getExhibitionsGroupedDetailsSucceeded,
		[ActionTypes.GET_EXHIBITIONS_GROUPED_DETAILS_FAILED]: getExhibitionsGroupedDetailsFailed,
		
		[ActionTypes.COPY_EXHIBITION_SETTINGS]: copyExhibitionSettings,
		[ActionTypes.COPY_EXHIBITION_SETTINGS_SUCCEEDED]: copyExhibitionSettingsSuccedded,
		[ActionTypes.COPY_EXHIBITION_SETTINGS_FAILED]: copyExhibitionSettingsFailed,
	}
}

export default exhibition
