//@flow
import React, { useMemo, useState } from 'react';
import { DataGridPremium, GRID_CHECKBOX_SELECTION_COL_DEF, GridActionsCellItem } from '@mui/x-data-grid-premium';
import ContentLoader from "../../../../../components/general/contentLoader/contentLoader";
import useExhibitorAction from "../../../../../hooks/exhibitions/useExhibitorAction";
import type { Exhibitor } from "../../../../../types/exhibition/types";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { getRouteUrl } from "../../../../../helpers/getRouteUrl";
import { ROUTE_PAGE_PROFILE_EXHIBITOR } from "../../../../../routers/routes";
import { Link } from "react-router-dom";
import Toolbar from "@mui/material/Toolbar";
import Divider from "@mui/material/Divider";
import { useTranslation } from "react-i18next";
import { EXHIBITOR_PROFILE } from "../../../../../locales/components/namespaces";
import { useMuiGridServerSide } from "../../../../../hooks/useMuiGridServerSide";
import { mapExhibitor } from "../../../../../types/exhibition/map";
import LoadingButton from "@mui/lab/LoadingButton";
import { EmailOutlined, Send, Settings } from "@mui/icons-material";
import { normalizeGreek } from "../../../../../helpers/normalizeGreek";
import ConfirmDialog from "../../../../../components/app/dialogs/confirmDialog/confirmDialog";
import ActionStatus from "../../../../../components/app/alerts/actionStatus/actionStatus";
import useExhibitionData from "../../../../../hooks/exhibitions/useExhibitionData";
import useExhibitionAction from "../../../../../hooks/exhibitions/useExhibitionAction";
import { EventStatus } from "../../../../../helpers/date";
import { useExhibition } from "../../../../../hooks/exhibitions/useExhibition";
import { ExhibitorSettings } from "../../exhibitorSettings";
import {
	showOption
} from "../../../../exhibitionSettings/components/subSettings/generalExhibitionSettings/components/selectExhibitionBusinessCategories";
import useUserData from "../../../../../hooks/user/useUserData";
import { useCountries } from "../../../../../hooks/countries/useCountries";
import { ExportInWordPopOver } from "../../exportInWordPopOver";
import { ExportInExcelPopOver } from "../../exportInExcelPopOver";
import { useHasPermission } from "../../../../../modules/permission/hooks/useHasPermission";
import { API, Permissions } from "../../../../../helpers/constants";
import TransferDataToEntersoft from "../../../../../components/app/transferDataToEntersoft/transferDataToEntersoft";
import { Paper, Tooltip } from "@mui/material";
import SendToOtherEmail from "../../../../../components/app/dialogs/sendToOtherEmail/sendToOtherEmail";
import dayjs from "dayjs";
import { muiGridLocales } from "../../../../../helpers/muiGridLocales";
import {
	CustomPagination
} from "../../../../../components/grids/dataGridCustom/pagination/customPaginations/customPagination";
import ToolbarWithState
	from "../../../../../components/grids/dataGridCustom/toolbars/toolbarWithState/toolbarWithState";
import GridMultiColumnSearch from "../../../../../components/app/gridMultiColumnSearch/gridMultiColumnSearch";

type Props = {
	exhibitors: Array<Exhibitor>
}

const GridToolbar = () => (
	<ToolbarWithState
		code={'exhibitors_page_all'}
		exportSlotProps={{
			csvOptions: {
				disableToolbarButton: true
			}
		}}
	/>
)

const Exhibitors = (props: Props) => {
	const { exhibitionId } = props
	const { language } = useUserData()
	const { t } = useTranslation(EXHIBITOR_PROFILE)
	const { countries } = useCountries()
	const { exhibition } = useExhibition({ id: exhibitionId })
	const { actionOnExhibitorStatus } = useExhibitionData()
	const { clearActionOnExhibitorStatus } = useExhibitionAction()
	const isOrganizer = useHasPermission([Permissions.BeOrganizer], { shouldView: true })
	const [transferExhibitors, setTransferExhibitors] = useState([])
	const [exhibitorSettings, setExhibitorSettings] = useState({
		anchorEl: null,
		open: false,
		exhibitorId: '',
		data: {
			catalogueLength: '',
			isKioskSignReadOnly: false,
			openExhibitorCatalogue: false
		}
	})
	const [sendToOtherEmail, setSendToOtherEmail] = useState({
		anchorEl: null,
		open: false,
		exhibitorId: ''
	})
	const [exportInWordAnchor, setExportInWordAnchor] = useState(null)
	const [exportInExcelAnchor, setExportInExcelAnchor] = useState(null)
	const [customSearch, setCustomSearch] = useState('')
	const url = `exhibition/${exhibitionId}/exhibitors`
	const {
		isLoading,
		data,
		total,
		page,
		pageSize,
		onPaginationModelChange,
		onSortModelChange,
		onFilterModelChange,
		refreshGrid
	} = useMuiGridServerSide({
		url,
		initialPageSize: 50,
		mapFunction: mapExhibitor,
		initialSortModel: [{ name: 'id', direction: 'descending' }],
		otherDataParams: {
			customSearch: customSearch
		}
	})
	const { invitations, sendInvitations, setInvitations } = useExhibitorAction({ exhibitionId, refreshGrid })
	
	const handleExhibitorSettings = (value) => {
		setExhibitorSettings({ ...value })
	}
	const handleSendToOtherEmail = (value) => {
		setSendToOtherEmail({ ...value })
	}
	const handleCloseExportInWord = () => setExportInWordAnchor(null)
	const handleCloseExportInExcel = () => setExportInExcelAnchor(null)
	
	const columns = useMemo(() => [
		{
			...GRID_CHECKBOX_SELECTION_COL_DEF,
			maxWidth: 40,
			minWidth: 40,
			filterable: false,
			sortable: false,
			groupable: false,
			aggregable: false,
			resizable: false
		},
		{
			headerName: t('Actions'),
			field: 'actions',
			type: 'actions',
			minWidth: 100,
			maxWidth: 100,
			resizable: false,
			renderCell: (({ row }) => (
				<>
					<Tooltip title={t('Exhibitor Settings')}>
						<GridActionsCellItem
							onClick={(evt) => setExhibitorSettings({
								anchorEl: evt.currentTarget,
								open: true,
								exhibitorId: row.id,
								data: {
									catalogueLength: row.catalogueProfileLength,
									isKioskSignReadOnly: row.isKioskSignReadOnly,
									openExhibitorCatalogue: row?.openExhibitorCatalogue
								}
							})}
							icon={<Settings/>}
							label={t('Exhibitor Settings')}
						/>
					</Tooltip>
					<Tooltip title={t('Send Invitation To Different Email')}>
						<GridActionsCellItem
							onClick={(evt) => setSendToOtherEmail({
								anchorEl: evt.currentTarget,
								open: true,
								exhibitorId: row.id
							})}
							icon={<EmailOutlined/>}
							label={t('Send Invitation To Different Email')}
						/>
					</Tooltip>
				</>
			))
		},
		{
			field: 'name',
			headerName: t('Name'),
			renderCell: ({ row }) => (
				<Link to={getRouteUrl(ROUTE_PAGE_PROFILE_EXHIBITOR, {
					id: exhibitionId,
					exhibitorId: row.id
				})}>
					{row.name}
				</Link>
			),
			flex: 1,
			minWidth: 300
		},
		{
			field: 'username',
			headerName: t('Username'),
			minWidth: 160,
			flex: 1,
		},
		{
			field: 'lastLogin',
			headerName: t('Last Login'),
			minWidth: 160,
			flex: 1,
			type: 'dateTime',
		},
		{ field: 'email', headerName: t('Email'), minWidth: 200, flex: 1 },
		{ field: 'email2', headerName: t('Email2'), minWidth: 200, flex: 1 },
		{ field: 'emailCatalogue', headerName: t('emailCatalogue'), minWidth: 200, flex: 1 },
		{ field: 'room', headerName: t('Hall'), minWidth: 160, flex: 1 },
		{ field: 'kiosk', headerName: t('Stand Number'), minWidth: 160, flex: 1 },
		{ field: 'kioskSign', headerName: t('Kiosk Sign'), minWidth: 160, flex: 1 },
		{
			field: 'catalog',
			headerName: t('Brand'),
			minWidth: 200,
			flex: 1,
			renderCell: ({ value }) => {
				const brand = { description: value.brand }
				return value?.brand ? showOption(language)(brand) : '-'
			},
		},
		{
			field: 'profile',
			headerName: t('Profile'),
			minWidth: 300,
			flex: 1,
			renderCell: ({ row }) => {
				const profile = { description: row.catalog.profile }
				const description = row?.catalog?.profile ? showOption(language)(profile) : ''
				return description && description.length > 0 ? `${description.slice(0, 44)} ...` : '-'
			},
		},
		{
			field: 'address1CountryId',
			headerName: t('Address 1 Country'),
			minWidth: 180,
			flex: 1,
			type: 'singleSelect',
			valueOptions: countries.map(type => ({
				value: type.id,
				label: type.description
			}))
		},
		{
			field: 'catalogueProfileLength',
			headerName: t('catalogueProfileLength'),
			minWidth: 160,
			flex: 1,
			valueFormatter: (value, row, column, apiRef) => value === '' ? exhibition.catalogueProfileLength : value
		},
		...(exhibition.isKioskSignEditable ? [{
			field: 'isKioskSignReadOnly',
			headerName: t('Disabled Kiosk Sign Edit'),
			flex: 1,
			minWidth: 200,
			type: 'boolean',
		}] : []),
		{
			field: 'confirmedCatalogue',
			headerName: t('confirmedCatalogue'),
			minWidth: 160,
			flex: 1,
			type: 'boolean'
		},
		{
			field: 'invitesToVisitorsCounter',
			headerName: t('Number of Invites'),
			minWidth: 160,
			flex: 1,
			type: 'number'
		},
		{
			field: 'adLogo',
			headerName: t('Advertisement Logo'),
			filterable: false,
			sortable: false,
			groupable: false,
			aggregable: false,
			minWidth: 160,
			flex: 1,
			renderCell: ({ row }) => {
				return (
					<img
						src={`${API}/en/exhibitor/get-logo/${exhibitionId}?ExhibitorId=${row.id}`}
						alt="logo"
						style={{ maxHeight: '34px', margin: 'auto' }}
					/>
				)
			}
		},
	], [countries, exhibition.catalogueProfileLength, exhibition.isKioskSignEditable, exhibitionId, language, t])
	
	const mapSortModel = model => {
		return model.map(f => {
			if (f.name === 'name') return { name: 'ExhibitorName', direction: f.direction }
			if (f.name === 'invitesToVisitorsCounter') return { name: 'VisitorCounter', direction: f.direction }
			if (f.name === 'kiosk') return { name: 'Kiosk', direction: f.direction }
			if (f.name === 'room') return { name: 'Room', direction: f.direction }
			if (f.name === 'kioskSign') return { name: 'KioskSign', direction: f.direction }
			if (f.name === 'email') return { name: 'ExhibitorEmail', direction: f.direction }
			if (f.name === 'email2') return { name: 'ExhibitorEmail2', direction: f.direction }
			if (f.name === 'emailCatalogue') return { name: 'EmailCatalogue', direction: f.direction }
			if (f.name === 'catalogueProfileLength') return { name: 'CatalogueProfileLength', direction: f.direction }
			if (f.name === 'confirmedCatalogue') return { name: 'ConfirmedCatalogue', direction: f.direction }
			if (f.name === 'username') return { name: 'userName', direction: f.direction }
			if (f.name === 'address1') return { name: 'Address1CountryId', direction: f.direction }
			if (f.name === 'catalog' && language === 'en') return { name: 'CatalogBrandEN', direction: f.direction }
			if (f.name === 'catalog' && language === 'el') return { name: 'CatalogBrandEL', direction: f.direction }
			if (f.name === 'profile' && language === 'en') return { name: 'CatalogProfileEN', direction: f.direction }
			if (f.name === 'profile' && language === 'el') return { name: 'CatalogProfileEL', direction: f.direction }
			return f
		})
	}
	
	const mapFilterModel = model => {
		if (model.length > 0) {
			model[0].predicates = model[0].predicates.map(f => {
				if (f.field === 'name') return { ...f, field: 'ExhibitorName' }
				if (f.field === 'invitesToVisitorsCounter') return { ...f, field: 'VisitorCounter' }
				if (f.field === 'kiosk') return { ...f, field: 'Kiosk' }
				if (f.field === 'room') return { ...f, field: 'Room' }
				if (f.field === 'kioskSign') return { ...f, field: 'KioskSign' }
				if (f.field === 'email') return { ...f, field: 'ExhibitorEmail' }
				if (f.field === 'email2') return { ...f, field: 'ExhibitorEmail2' }
				if (f.field === 'emailCatalogue') return { ...f, field: 'EmailCatalogue' }
				if (f.field === 'catalogueProfileLength') return { ...f, field: 'CatalogueProfileLength' }
				if (f.field === 'confirmedCatalogue') return { ...f, field: 'ConfirmedCatalogue' }
				if (f.field === 'username') return { ...f, field: 'userName' }
				if (f.field === 'address1') return { ...f, field: 'Address1CountryId' }
				if (f.field === 'catalog' && language === 'en') return { ...f, field: 'CatalogBrandEN' }
				if (f.field === 'catalog' && language === 'el') return { ...f, field: 'CatalogBrandEL' }
				if (f.field === 'profile' && language === 'en') return { ...f, field: 'CatalogProfileEN' }
				if (f.field === 'profile' && language === 'el') return { ...f, field: 'CatalogProfileEL' }
				return f
			})
			return model
		}
	}
	
	return (
		<Box sx={{ width: '91vw' }}>
			<Toolbar disableGutters variant={"dense"}>
				<Box sx={{ flexGrow: 1 }}/>
				<Stack direction="row" width={'100%'} justifyContent={'space-between'} flexWrap={'wrap'}>
					{(isOrganizer && exhibition.status === EventStatus.EXPIRED) && (
						<TransferDataToEntersoft exhibitionId={exhibitionId} ids={transferExhibitors}/>
					)}
					{((isOrganizer && exhibition.status !== EventStatus.EXPIRED) || (!isOrganizer && dayjs().isBefore(exhibition.dateFrom, 'date'))) && (
						<Stack spacing={1} direction={"row"} flexWrap={'wrap'} sx={{ mb: 1 }}>
							<LoadingButton
								disabled={invitations?.selectionModel.length === 0}
								loading={invitations.loading}
								loadingPosition="start"
								variant={"outlined"}
								size={"small"}
								startIcon={<Send/>}
								onClick={() => {
									sendInvitations({
										type: 'ids',
										selectionModel: invitations.selectionModel,
										recipientEmail: '',
										returnCount: true
									})
								}}
							>
								{normalizeGreek(t('Send invitation to {{count}} exhibitors', { count: invitations?.selectionModel.length }))}
							</LoadingButton>
							<LoadingButton
								loading={invitations.loading}
								loadingPosition="start"
								variant={"outlined"}
								size={"small"}
								startIcon={<Send/>}
								onClick={() => {
									sendInvitations({
										type: 'notsent',
										selectionModel: [],
										recipientEmail: '',
										returnCount: true
									})
								}}
							>
								{normalizeGreek(t('Send Invitation (New)'))}
							</LoadingButton>
							<LoadingButton
								loading={invitations.loading}
								loadingPosition="start"
								variant={"outlined"}
								size={"small"}
								startIcon={<Send/>}
								onClick={() => {
									sendInvitations({
										type: 'all',
										selectionModel: [],
										recipientEmail: '',
										returnCount: true
									})
								}}
							>
								{normalizeGreek(t('Send Invitation (All)'))}
							</LoadingButton>
						</Stack>
					)}
					<Stack spacing={1} direction={"row"} flexWrap={'wrap'} sx={{ mb: 1 }}>
						<Button
							variant={"outlined"}
							size={"small"}
							sx={{
								mr: 1
							}}
							onClick={(evt) => setExportInWordAnchor(evt.currentTarget)}
						>
							{t('EXPORT CATALOG DATA IN WORD')}
						</Button>
						<Button
							variant={"outlined"}
							size={"small"}
							sx={{
								mr: 1
							}}
							onClick={(evt) => setExportInExcelAnchor(evt.currentTarget)}
						>
							{t('EXPORT CATALOG DATA IN EXCEL')}
						</Button>
					</Stack>
				</Stack>
			</Toolbar>
			<Divider sx={{ mb: 2 }} light/>
			<Paper sx={{ mb: 1, pt: 1, pl: 1, pr: 1 }} variant={"outlined"}>
				<GridMultiColumnSearch
					t={t}
					customSearch={customSearch}
					setCustomSearch={setCustomSearch}
					refreshGrid={refreshGrid}
				/>
			</Paper>
			<div style={{ height: 600 }}>
				<DataGridPremium
					disableRowGrouping
					disableAggregation
					sx={{ bgcolor: 'background.paper' }}
					initialState={{
						density: "compact",
						pinnedColumns: {
							left: [GRID_CHECKBOX_SELECTION_COL_DEF.field, 'actions']
						},
					}}
					columns={columns}
					loading={isLoading}
					rows={data}
					rowCount={Number(total)}
					localeText={muiGridLocales(language)}
					pageSizeOptions={[5, 10, 15, 20, 50, 100, 200]}
					pagination
					paginationMode='server'
					paginationModel={{ page, pageSize }}
					onPaginationModelChange={onPaginationModelChange}
					sortingMode="server"
					onSortModelChange={(sortModel) => onSortModelChange(sortModel, mapSortModel)}
					filterMode='server'
					onFilterModelChange={(filterModel, details) => onFilterModelChange(filterModel, details, mapFilterModel)}
					checkboxSelection={Boolean(exhibition)}
					rowSelectionModel={exhibition.status !== EventStatus.EXPIRED ? invitations?.selectionModel : transferExhibitors}
					onRowSelectionModelChange={(newSelectionModel) => exhibition && (exhibition.status !== EventStatus.EXPIRED ? setInvitations(old => ({
						...old,
						selectionModel: newSelectionModel
					})) : setTransferExhibitors(newSelectionModel))
					}
					slots={{
						toolbar: GridToolbar,
						pagination: CustomPagination
					}}
				/>
			</div>
			<ConfirmDialog
				onYes={() => {
					sendInvitations({
						type: invitations.type,
						selectionModel: invitations.selectionModel,
						recipientEmail: invitations.recipientEmail,
						returnCount: false
					})
				}}
				onNo={() => {
					setInvitations(old => ({ ...old, openConfirmDialog: false }))
				}}
				title={t('Confirm Sent')}
				open={invitations?.openConfirmDialog}
				handleDialog={(newValue) => {
					setInvitations(old => ({ ...old, openConfirmDialog: newValue }))
				}}
			>
				{invitations.confirmationMessage ? t(invitations.confirmationMessage?.message, invitations.confirmationMessage?.parameters ?? {}) : ''}
			</ConfirmDialog>
			<ExhibitorSettings
				exhibitorSettings={exhibitorSettings}
				handleExhibitorSettings={handleExhibitorSettings}
				refreshGrid={refreshGrid}
				exhibitionId={exhibitionId}
				exhibition={exhibition}
			/>
			<ExportInWordPopOver
				exhibitionId={exhibitionId}
				exportInWordAnchor={exportInWordAnchor}
				handleCloseExportInWord={handleCloseExportInWord}
			/>
			<ExportInExcelPopOver
				exhibitionId={exhibitionId}
				exportInExcelAnchor={exportInExcelAnchor}
				handleCloseExportInExcel={handleCloseExportInExcel}
			/>
			<SendToOtherEmail
				sendInvitations={sendInvitations}
				sendToOtherEmail={sendToOtherEmail}
				handleSendToOtherEmail={handleSendToOtherEmail}
			/>
			<ActionStatus
				status={actionOnExhibitorStatus}
				onClose={() => {
					clearActionOnExhibitorStatus();
					refreshGrid()
				}}
			/>
			<ContentLoader
				withBackDrop={true}
				useCircularLoader={true}
				loading={invitations.loading}
				message={invitations.loading ? t(invitations.returnCount ? "Calculating number of emails to send... Please don't close the browser before the process ends." : "Creating emails to send... Please don't close the browser before the process ends.") : ''}
			/>
		</Box>
	)
}

export default Exhibitors;