// @flow
import * as React from 'react';
import Popover from "@mui/material/Popover";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { ErrorMessage, FormikProvider, useFormik } from "formik";
import ExhibitionUpdateStatus from "../../../components/app/alerts/exhibitionUpdateStatus/exhibitionUpdateStatus";
import { normalizeGreek } from "../../../helpers/normalizeGreek";
import { useTranslation } from "react-i18next";
import CancelIcon from "@mui/icons-material/Cancel";
import * as yup from "yup";
import { Checkbox, FormControl, FormControlLabel, FormHelperText } from "@mui/material";
import { EXHIBITOR_PROFILE } from "../../../locales/components/namespaces";
import SaveIcon from "@mui/icons-material/Save";
import useExhibitionAction from "../../../hooks/exhibitions/useExhibitionAction";
import TextField from "@mui/material/TextField";

type Props = {};

export const ExhibitorSettings = (props: Props) => {
	const { exhibitorSettings, handleExhibitorSettings, refreshGrid, exhibitionId, exhibition } = props
	const { t } = useTranslation(EXHIBITOR_PROFILE)
	const { updateExhibitorProfile } = useExhibitionAction()
	
	const onSubmit = (values) => {
		const data = {
			openExhibitorCatalogue: values.openExhibitorCatalogue,
			isKioskSignReadOnly: values.isKioskSignReadOnly,
			CatalogueProfileLength: values.catalogueProfileLength
		}
		updateExhibitorProfile(exhibitorSettings.exhibitorId, exhibitionId, data).then(() => {
			refreshGrid();
			handlePopoverClose()
		})
	}
	
	const validationSchema = yup.object({
		catalogueProfileLength: yup.number().positive().integer().min(200, t('Catalogue length must be at least 200 characters')).nullable()
	})
	
	const formik = useFormik({
		initialValues: {
			catalogueProfileLength: exhibitorSettings.data.catalogueLength,
			isKioskSignReadOnly: exhibitorSettings.data.isKioskSignReadOnly,
			openExhibitorCatalogue: exhibitorSettings.data.openExhibitorCatalogue
		},
		validationSchema: validationSchema,
		onSubmit: onSubmit,
		enableReinitialize: true
	})
	
	const handlePopoverClose = () => {
		handleExhibitorSettings({
			anchorEl: null,
			open: false,
			exhibitorId: '',
			data: {
				catalogueLength: '',
				isKioskSignReadOnly: false,
				openExhibitorCatalogue: false
			}
		});
		formik.resetForm()
	}
	
	return (
		<React.Fragment>
			<Box
				onClick={(e) => e.stopPropagation()}
			>
				<Popover
					disableRestoreFocus
					onClose={handlePopoverClose}
					open={exhibitorSettings.open}
					anchorEl={exhibitorSettings.anchorEl}
					anchorOrigin={{
						vertical: 'top',
						horizontal: 'center',
					}}
					transformOrigin={{
						vertical: 'bottom',
						horizontal: 'center',
					}}
				>
					<Box p={2} sx={{ maxWidth: 400 }}>
						<FormikProvider value={formik}>
							<Box
								component="form"
								onSubmit={formik.handleSubmit}
							>
								<FormControl
									variant="standard"
									fullWidth
									error={
										formik.touched["catalogueProfileLength"] &&
										Boolean(formik.errors["catalogueProfileLength"])
									}
								>
									<TextField
										id="outlined-name"
										label={t('catalogueProfileLength')}
										value={formik.values.catalogueProfileLength}
										name={`catalogueProfileLength`}
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										fullWidth
										size={'small'}
										type={'number'}
									/>
									<FormHelperText>
										<ErrorMessage name={"catalogueProfileLength"}/>
									</FormHelperText>
								</FormControl>
								{exhibition.isKioskSignEditable && (
									<FormControl
										variant="standard"
										fullWidth
										error={
											formik.touched["isKioskSignReadOnly"] &&
											Boolean(formik.errors["isKioskSignReadOnly"])
										}
									>
										<FormControlLabel
											control={
												<Checkbox
													checked={formik.values.isKioskSignReadOnly}
													onChange={formik.handleChange}
													size={'small'}
													name={`isKioskSignReadOnly`}
													onBlur={formik.handleBlur}
												/>
											}
											label={t('Kiosk Sign Not Editable')}
											labelPlacement="end"
										/>
										<FormHelperText>
											<ErrorMessage name={"isKioskSignReadOnly"}/>
										</FormHelperText>
									</FormControl>
								)}
								<FormControl
									variant="standard"
									fullWidth
									error={
										formik.touched["openExhibitorCatalogue"] &&
										Boolean(formik.errors["openExhibitorCatalogue"])
									}
								>
									<FormControlLabel
										control={
											<Checkbox
												checked={formik.values.openExhibitorCatalogue}
												onChange={formik.handleChange}
												size={'small'}
												name={`openExhibitorCatalogue`}
												onBlur={formik.handleBlur}
											/>
										}
										label={t('Open Exhibitor Catalogue')}
										labelPlacement="end"
									/>
									<FormHelperText>
										<ErrorMessage name={"openExhibitorCatalogue"}/>
									</FormHelperText>
								</FormControl>
								<Box sx={{
									display: 'flex',
									width: '100%',
									justifyContent: 'space-between'
								}}>
									<Button
										size={"small"}
										type={"submit"}
										variant={"contained"}
										startIcon={<SaveIcon/>}
									>
										{normalizeGreek(t('Save'))}
									</Button>
									<Button
										size={"small"}
										variant={"outlined"}
										startIcon={<CancelIcon/>}
										type={"button"}
										onClick={handlePopoverClose}
									>
										{normalizeGreek(t('Cancel'))}
									</Button>
								</Box>
							</Box>
						</FormikProvider>
					</Box>
				</Popover>
			</Box>
			<ExhibitionUpdateStatus/>
		</React.Fragment>
	)
};