import axios from "axios";
import { enqueueSnackbar } from "notistack";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { API } from "../../helpers/constants";
import { handleAxiosErrors } from "../../helpers/errorHandling";
import useUserData from "../user/useUserData";
import { EXHIBITION } from "../../locales/components/namespaces";

const useVisitorActions = ({ exhibitorId, exhibitionId, refreshGrid }) => {
	const { token } = useUserData()
	const { t } = useTranslation(EXHIBITION)
	const [invitations, setInvitations] = useState({
		loading: false,
		openConfirmDialog: false,
		type: 'all',
		selectionModel: [],
		recipientEmail: '',
		returnCount: false,
		confirmationMessage: null
	})
	const [confirmDeleteVisitor, setConfirmDeleteVisitor] = useState({
		open: false,
		loading: false,
		id: ''
	})
	const [confirmDeleteVisitors, setConfirmDeleteVisitors] = useState({
		open: false,
		loading: false,
		ids: ''
	})
	
	const sendInvitations = useCallback((data) => {
		const { type, selectionModel, returnCount, recipientEmail } = data
		setInvitations(old => ({ ...old, loading: true, returnCount: returnCount }))
		axios({
			url: `${API}/en/exhibitor/${exhibitorId ? exhibitorId : 0}/visitor/sendinvitations`,
			method: 'post',
			data: {
				type: type,
				ExhibitionId: exhibitionId,
				ids: selectionModel,
				returnCount: returnCount,
				...(recipientEmail ? { sendto: recipientEmail } : {})
			},
			headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
		}).then((response) => {
			const { mailcount } = response.data
			const message = returnCount ? '{{count}} emails will be sent in the next hours' : '{{count}} emails where created and are queued for sending. For large number of emails, this process may take several hours!'
			if (returnCount) {
				setInvitations({
					loading: false,
					openConfirmDialog: true,
					type: type,
					selectionModel: selectionModel,
					recipientEmail: recipientEmail,
					returnCount: false,
					confirmationMessage: {
						type: 'success',
						message: message,
						parameters: { count: mailcount }
					}
				})
			} else {
				setInvitations({
					loading: false,
					openConfirmDialog: false,
					type: 'all',
					selectionModel: [],
					recipientEmail: '',
					returnCount: false,
					confirmationMessage: null
				})
				console.log(t(message, { count: mailcount }))
				enqueueSnackbar(t(message, { count: mailcount } ?? {}), {
					variant: 'success',
				})
				refreshGrid()
			}
		}).catch((error) => {
			setInvitations({
				loading: false,
				openConfirmDialog: false,
				type: 'all',
				selectionModel: [],
				recipientEmail: '',
				returnCount: false,
				confirmationMessage: null
			})
			const message = error?.response?.data?.errors
			enqueueSnackbar(handleAxiosErrors(error, t(message)), {
				variant: 'error',
				autoHideDuration: null,
			})
		})
	}, [exhibitionId, exhibitorId, refreshGrid, t, token])
	
	const deleteVisitor = useCallback(() => {
		setConfirmDeleteVisitor(old => ({
			...old,
			loading: true
		}))
		axios({
			url: `${API}/en/visitor/delete/${confirmDeleteVisitor.id}`,
			method: 'post',
			data: {
				ExhibitionId: exhibitionId,
				...(exhibitorId ? { ExhibitorId: exhibitorId } : {})
			},
			headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
		}).then((response) => {
			enqueueSnackbar(t('Visitor removed successfully!'), {
				variant: 'success'
			})
			setConfirmDeleteVisitor(old => ({
				open: false,
				loading: false,
				id: ''
			}))
			refreshGrid()
		}).catch((error) => {
			setConfirmDeleteVisitor(old => ({
				open: false,
				loading: false,
				id: ''
			}))
			enqueueSnackbar(handleAxiosErrors(error, error?.response?.data?.errors), {
				variant: 'error',
				autoHighlight: null
			})
			refreshGrid()
		})
	}, [confirmDeleteVisitor.id, exhibitionId, exhibitorId, refreshGrid, t, token])
	
	const deleteMultipleVisitors = useCallback(() => {
		setConfirmDeleteVisitors(old => ({
			...old,
			loading: true
		}))
		axios({
			url: `${API}/en/visitor/delete-multiple`,
			method: 'post',
			data: {
				ExhibitionId: exhibitionId,
				ids: confirmDeleteVisitors.ids
			},
			headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
		}).then((response) => {
			setConfirmDeleteVisitors(old => ({
				open: false,
				loading: false,
				id: ''
			}))
			enqueueSnackbar(t('Visitors removed successfully!'), {
				variant: 'success'
			})
			refreshGrid()
		}).catch((error) => {
			setConfirmDeleteVisitors(old => ({
				open: false,
				loading: false,
				id: ''
			}))
			enqueueSnackbar(handleAxiosErrors(error, error?.response?.data?.errors), {
				variant: 'error',
				autoHighlight: null
			})
			refreshGrid()
		})
	}, [confirmDeleteVisitors.ids, exhibitionId, refreshGrid, t, token])
	
	return {
		invitations,
		setInvitations,
		sendInvitations,
		deleteVisitor,
		deleteMultipleVisitors,
		confirmDeleteVisitor,
		setConfirmDeleteVisitor,
		confirmDeleteVisitors,
		setConfirmDeleteVisitors
	}
}

export default useVisitorActions