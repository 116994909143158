import _ from "lodash";
import { eventStatus } from "../../helpers/date";
import { parseMultiple, withLanguage } from "../../helpers/parse";
import { parseInterests } from "../interests/parse";
import type { Exhibition, Exhibitor, FAQ, MessageStatus, Partner, Visitor, VisitorReference } from "./types";

export const sortExhibitions = _.memoize((items) => {
    const statusGroups = _.groupBy(items, function (i) {
        const {dateFrom, dateTo} = i
        return eventStatus(dateFrom, dateTo)
    })
    return _.flatten([
        _.orderBy(statusGroups[eventStatus.status.ONGOING], ['dateFrom'], ['asc']),
        _.orderBy(statusGroups[eventStatus.status.UPCOMING], ['dateFrom'], ['asc']),
        _.orderBy(statusGroups[eventStatus.status.EXPIRED], ['dateFrom'], ['desc']),
    ])
})

export const sortFAQs = _.memoize((items) => _.orderBy(items, ['weight', 'id'], ['asc', 'asc']))

export const parseFAQ: FAQ = (datum, language) => {
    if (!datum) return
    return {
        id: datum.get('id'),
        title: withLanguage(language)(datum.get('title')),
        content: withLanguage(language)(datum.get('content')),
        weight: datum.get('weight'),
        parent: datum.get('parent'),
        isNew: datum.get('isNew')
    }
}

export const parseExhibitionsGroupedHeaders = (datum, language) => {
    if (!datum) return;
    return {
        DescriptionEN: datum.get('DescriptionEN'),
        description: withLanguage(language)(datum.get('description'))
    }
}

export const parseBusinessCategory = (datum, language) => {
    if (!datum) return;
    return {
        id: datum.get('id'),
        description: withLanguage(language)(datum.get('description'))
    }
}

export const parseVisitorReference: VisitorReference = (datum, language) => {
    if (!datum) return
    return {
        id: datum.get('id'),
        exhibitor: {
            id: datum.getIn(['exhibitor', 'id']),
            name: datum.getIn(['exhibitor', 'name']),
        },
        invitationDate: datum.get('invitationDate'),
        statusId: datum.get('statusId')
    }
}

const parseFile = (datum) => {
    if (!datum) return;
    return {
        id: datum.get('id'),
        name: datum.get('name'),
        url: datum.get('url'),
    }
}

export const parseTranslations = (datum, language, inExhibition = true) => {
    if (!datum) return
    const translations = {}
    for (let [key, value] of Object.entries(datum?.toJS())) {
        translations[key] = {
            value: inExhibition ? (language === 'any' ? {
                el: value?.value?.el,
                en: value?.value?.en
            } : value?.value?.[language]) : value?.value,
            description: language === 'any' ? {
                el: value?.description?.el,
                en: value?.description?.en
            } : value?.description?.[language]
        }
    }
    return translations
}

export const parseExhibition: Exhibition = (datum, language) => {
    if (!datum) return;
    return {
        id: datum.get('id'),
        erpCode: datum.get('erpCode'),
        dateFrom: datum.get('dateFrom'),
        dateTo: datum.get('dateTo'),
        daysForCatalogSubmission: datum.get('daysForCatalogSubmission'),
        typeId: datum.get('typeId'),
        categoryId: datum.get('categoryId'),
        familyId: datum.get('familyId'),
        numberOfPartnersPerExhibitor: datum.get('numberOfPartnersPerExhibitor'),
        preregistrationEnabled: datum.get('preregistrationEnabled'),
        preregistrationFormFields: datum.get('preregistrationFormFields')?.toJS(),
        lastModifiedBy: datum.get('lastModifiedBy'),
        lastModifiedAt: datum.get('lastModifiedAt'),
        isRequireRegistration: datum.get('isRequireRegistration'),
        type: datum.get('type'),
        category: parseMultiple(datum.get('category'), (i) => i),
        family: datum.get('family'),
        image: withLanguage(language)(datum.get('image')),
        title: withLanguage(language)(datum.get('title')),
        description: withLanguage(language)(datum.get('description')),
        welcomeMessage: withLanguage(language)(datum.get('welcomeMessage')),
        preregistrationFrom: datum.get('preregistrationFrom'),
        preregistrationTo: datum.get('preregistrationTo'),
        preregistrationNeedsApproval: datum.get('preregistrationNeedsApproval'),
        catalogLocked: datum.get('catalogLocked'),
        catalogEnableKioskSign: datum.get('catalogEnableKioskSign'),
        catalogueLanguage: datum.get('catalogueLanguage'),
		displayExhibitorKitAndAddsToExhibitor: datum.get('displayExhibitorKitAndAddsToExhibitor'),
        displayVisitorsToExhibitor: datum.get('displayVisitorsToExhibitor'),
        displayPartnersToExhibitor: datum.get('displayPartnersToExhibitor'),
        displayServiceProvidersToExhibitor: datum.get('displayServiceProvidersToExhibitor'),
        displayStaffToExhibitor: datum.get('displayStaffToExhibitor'),
        displayOrganizersToExhibitor: datum.get('displayOrganizersToExhibitor'),
        hasVisitors: datum.get('hasVisitors'),
        hasPartners: datum.get('hasPartners'),
        hasServiceProviders: datum.get('hasServiceProviders'),
        hasStaff: datum.get('hasStaff'),
        hasOrganizers: datum.get('hasOrganizers'),
        exhibitorInvitesVisitorsWithBarcode: datum.get('exhibitorInvitesVisitorsWithBarcode'),
        coorganizationId: datum.get('coorganizationId'),
        coorganizationDescription: withLanguage(language)(datum.get('coorganizationDescription')),
        visitorCategoryRequiredInImport: datum.get('visitorCategoryRequiredInImport'),
        preregistrationHeaderImageLink: datum.get('preregistrationHeaderImageLink'),
        googleTagCode: datum.get('googleTagCode'),
        preregistrationHasFieldGroups: datum.get('preregistrationHasFieldGroups'),
        domainPortal: datum.get('domainPortal'),
        domainPreregistration: datum.get('domainPreregistration'),
        domainRegistration: datum.get('domainRegistration'),
        disableRegistrationMessages: datum.get('disableRegistrationMessages'),
        preregistrationCountry: datum.get('preregistrationCountry'),
        preregistrationApprovePerCountry: datum.get('preregistrationApprovePerCountry'),
        catalogueProfileLength: datum.get('catalogueProfileLength'),
        hasExhibitorBrands: datum.get('hasExhibitorBrands'),
        visitorBadgeTemplateTypeId: datum.get('visitorBadgeTemplateTypeId'),
        visitorRegBadgeTemplateTypeId: datum.get('visitorRegBadgeTemplateTypeId'),
        partnerBadgeTemplateTypeId: datum.get('partnerBadgeTemplateTypeId'),
        providerBadgeTemplateTypeId: datum.get('providerBadgeTemplateTypeId'),
        staffBadgeTemplateTypeId: datum.get('staffBadgeTemplateTypeId'),
        organizerBadgeTemplateTypeId: datum.get('organizerBadgeTemplateTypeId'),
        sendFromEmail: datum.get('sendFromEmail'),
        sendFromName: datum.get('sendFromName'),
        sendSmsName: datum.get('sendSmsName'),
        isKioskSignEditable: datum.get('isKioskSignEditable'),
        registrationStartDays: datum.get('registrationStartDays'),
        registrationEndDays: datum.get('registrationEndDays'),
        validQrDays: datum.get('validQrDays'),
        quickPrintFooterImage: datum.get('quickPrintFooterImage')?.toJS(),
        daysForCatalogNotification: datum.get('daysForCatalogNotification'),
        // TODO::Νομίζω δεν χρησιμοποιούνται πια. Προς αφαίρεση.
        status: datum.get('status'),
        files: parseMultiple(datum.get('files'), parseFile),
        exhibitors: parseMultiple(datum.get('exhibitors'), parseExhibitor),
        visitors: parseMultiple(datum.get('visitors'), parseVisitor),
        badgeConfig: datum.get('badgeConfig')?.toJS(),
        b2bEnabled: datum.get('b2bEnabled'),
        partners: parseMultiple(datum.get('partners'), parsePartner),
		translations: parseTranslations(datum.get('translations'), language),
		buttonColor: datum.get('buttonColor')
    }
}

const parseAddress = (datum, language) => {
    if (!datum) return
    return {
        country: withLanguage(language)(datum.get('country')),
        phoneCode: withLanguage(language)(datum.get('phoneCode')),
        category: datum.get('category'),
        street: withLanguage(language)(datum.get('street')),
        postalCode: withLanguage(language)(datum.get('postalCode')),
        city: withLanguage(language)(datum.get('city')),
        phone: withLanguage(language)(datum.get('phone'))
    }
}

export const parseExhibitor: Exhibitor = (datum, language) => {
    if (!datum) return
    return {
        code: datum.get('code'),
        id: datum.get('id'),
        businessCategories: parseMultiple(datum.get('businessCategories'), (i) => parseBusinessCategory(i, language)),
        address1: parseAddress(datum.get('address1'), language),
        address2: parseAddress(datum.get('address2'), language),
        businessPhoneNumber: datum.get('businessPhoneNumber'),
        homePhoneNumber: datum.get('homePhoneNumber'),
        phoneNumber: datum.get('phoneNumber'),
        email: datum.get('email'),
        email2: datum.get('email2'),
        emailCatalogue: datum.get('emailCatalogue'),
        name: datum.get('name'),
        kiosk: datum.get('kiosk'),
        kioskSign: datum.get('kioskSign'),
        room: datum.get('room'),
        visitors: parseMultiple(datum.get('visitors'), parseVisitor),
        partners: parseMultiple(datum.get('partners'), parsePartner),
        catalog: {
            brand: withLanguage(language)(datum.getIn(['catalog', 'brand'])),
            profile: withLanguage(language)(datum.getIn(['catalog', 'profile'])),
        },
        website: datum.get('website'),
        social: {
            fb: datum.getIn(['social', 'fb']),
            instagram: datum.getIn(['social', 'instagram']),
            linkedIn: datum.getIn(['social', 'linkedIn']),
        },
        catalogueProfileLength: datum.get('catalogueProfileLength'),
        lastLogin: datum.get('lastLogin'),
        username: datum.get('username'),
        confirmedCatalogue: datum.get('confirmedCatalogue'),
        editMode: datum.get('editMode'),
        brands: parseMultiple(datum.get('brands'), (i) => i),
		isKioskSignReadOnly: datum.get('isKioskSignReadOnly'),
        openExhibitorCatalogue: datum.get('openExhibitorCatalogue'),
		invitesToVisitorsCounter: datum.get('invitesToVisitorsCounter')
    }
}

export const parseVisitor: Visitor = (datum, language) => {
    if (!datum) return
    return {
        Badge: datum.get('Badge'),
        Characterization: datum.get('Characterization'),
        City: datum.get('City'),
        CompanyAddress: datum.get('CompanyAddress'),
        CompanyFirm: datum.get('CompanyFirm'),
        CompanyName: datum.get('CompanyName'),
        ContactId: datum.get('ContactId'),
        CountryId: datum.get('CountryId'),
        ExhibitionId: datum.get('ExhibitionId'),
        InvitedBy: {
            id: datum.getIn(['InvitedBy', 'id']),
            name: datum.getIn(['InvitedBy', 'name']),
            totalInvitations: datum.getIn(['InvitedBy', 'totalInvitations'])
        },
        InvitationDt: datum.get('InvitationDt'),
        LastModifiedAt: datum.get('LastModifiedAt'),
        PersonCount: datum.get('PersonCount'),
        PostalCode: datum.get('PostalCode'),
        Status: datum.get('Status'),
        StatusId: datum.get('StatusId'),
        VatNumber: datum.get('VatNumber'),
        VisitorBusinessPhone: datum.get('VisitorBusinessPhone'),
        VisitorEmail: datum.get('VisitorEmail'),
        VisitorMobilePhone: datum.get('VisitorMobilePhone'),
        VisitorName: datum.get('VisitorName'),
        WebSite: datum.get('Website'),
        Instagram: datum.get('Instagram'),
        Facebook: datum.get('Facebook'),
        businessCategories: parseMultiple(datum.get('businessCategories'), (i) => parseBusinessCategory(i, language)),
        existsInExhibition: datum.get('existsInExhibition'),
        hasRevisited: datum.get('hasRevisited'),
        id: datum.get('id'),
        interests: parseMultiple(datum.get('interests'), (i) => parseInterests(i, language)),
        isApproveRegistration: datum.get('isApproveRegistration'),
        isIndividual: datum.get('isIndividual'),
        organizerApprovalRejectReason: datum.get('organizerApprovalRejectReason'),
        FromRegistration: datum.get('FromRegistration'),
        FromPreregistration: datum.get('FromPreregistration'),
    }
}

export const parsePartner: Partner = (datum, language) => {
    if (!datum) return
    return {
        id: datum.get('id'),
        email: datum.get('email'),
        name: datum.get('name'),
        nameEn: datum.get('nameEn'),
        badgeCode: datum.get('badgeCode'),
        type: datum.get('type'),
        companyName: datum.get('companyName')
    }
}

export const parseBulkImportStatus = (datum) => {
    if (!datum) return
    return {
        importNum: datum.get('importNum'),
        statusCode: datum.get('statusCode'),
        message: datum.get('message'),
        newItems: parseMultiple(datum.get('newItems'), (i) => i),
        errors: parseMultiple(datum.get('errors'), (i) => i),
    }
}

export const parseMessageStatus: MessageStatus = (datum) => {
    if (!datum) return
    return {
        time: datum.get('time'),
        type: datum.get('type'),
        message: datum.get('message'),
        name: datum.get('name'),
        parameters: datum.has('parameters') ?
            datum.get('parameters').toJS() : null,
    }
}

