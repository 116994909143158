import { enqueueSnackbar } from "notistack";
import {useEffect, useMemo, useState} from "react";
import { useTranslation } from "react-i18next";
import { EXHIBITION } from "../../locales/components/namespaces";
import {useMessageTemplatesAction} from "./useMessageTemplatesAction";
import {useMessageTemplatesData} from "./useMessageTemplatesData";

const useMessageTemplate = (exhibitionId, dontFetchVariables = false, dontFetchTemplate = false) => {
    const {t} = useTranslation(EXHIBITION)
    const [messageTemplateTypeId, setMessageTemplateTypeId] = useState('')
    const {
        loading: loadingMessageTemplate,
        messageTemplate,
        messageTemplateTypes,
        messageTemplateTypeVariables,
        messageToUser
    } = useMessageTemplatesData()
    const {
        getMessageTemplate,
        getMessageTemplateTypes,
        getMessageTemplateTypeVariables,
        updateMessageTemplate,
        setMessageToUser
    } = useMessageTemplatesAction(exhibitionId)

    useEffect(() => {
        getMessageTemplateTypes()
    }, [getMessageTemplateTypes])
    
    useEffect(() => {
        if(messageTemplateTypeId === '' && messageTemplateTypes.length > 0) {
            setMessageTemplateTypeId(messageTemplateTypes[0].id)
        }
    }, [getMessageTemplateTypes, messageTemplateTypeId, messageTemplateTypes])

    useEffect(() => {
        if(!dontFetchVariables && messageTemplateTypeId !== '') {
            getMessageTemplateTypeVariables(messageTemplateTypeId)
        }
    }, [messageTemplateTypeId, getMessageTemplateTypeVariables, dontFetchVariables])

    useEffect(() => {
        if(!dontFetchTemplate && messageTemplateTypeId !== '') {
            getMessageTemplate(messageTemplateTypeId)
        }
    }, [dontFetchTemplate, getMessageTemplate, messageTemplateTypeId])
    
    useEffect(() => {
        if (messageToUser) {
            enqueueSnackbar(t(messageToUser?.text), {
                    variant: messageToUser?.variant
                }
            )
            setMessageToUser(null)
        }
    }, [messageToUser, setMessageToUser, t])

    const selectedMessageTemplateType = useMemo(() => {
        return messageTemplateTypes.find(f => f.id === messageTemplateTypeId)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [messageTemplateTypeId])

    const onChangeMessageTemplateType = (evt, fetchTemplate = true) => {
        const selectedTypeId = evt.target.value
        setMessageTemplateTypeId(selectedTypeId)
        if(fetchTemplate) {
            getMessageTemplate(selectedTypeId)
        }
    }

    const onSubmit = (values) => {
        updateMessageTemplate(values, messageTemplateTypeId)
    }

    return {
        loadingMessageTemplate,
        messageTemplateTypeId,
        selectedMessageTemplateType,
        messageTemplate,
        messageTemplateTypes,
        messageTemplateTypeVariables,
        onSubmit,
        onChangeMessageTemplateType
    }

}

export default useMessageTemplate