import {createSelector} from "reselect";
import {useSelector} from "react-redux";
import {
    parseMessageTemplate,
    parseMessageTemplateType,
    parseMessageTemplateTypeVariables
} from "../../types/messageTemplates/parse";
import type {
    MessageTemplate,
    MessageTemplateType,
    MessageTemplateTypeVariable
} from "../../types/messageTemplates/types";
import {parseMultiple} from "../../helpers/parse";
import _ from "lodash";
import { parseUserMessage } from "../../types/user/parse";

const selectMessage = createSelector(
    (state) => state.messageTemplates.get('message'),
    (item) => parseUserMessage(item)
)

const selectLoading = createSelector(
    (state) => state.messageTemplates.get('loading'),
    (item) => item
)

const selectMessageTemplate = createSelector(
    (state) => state.messageTemplates.get('messageTemplate'),
    (item) => parseMessageTemplate(item)
)

const selectMessageTemplateTypes = createSelector(
    (state) => state.messageTemplates.get('messageTemplateTypes'),
    (data) => parseMultiple(data, (i) => parseMessageTemplateType(i))
)

const selectMessageTemplateTypeVariables = createSelector(
    (state) => state.messageTemplates.get('messageTemplateTypeVariables'),
    (data) => parseMultiple(data, (i) => parseMessageTemplateTypeVariables(i))
)

export const useMessageTemplatesData = () => {
    const loading: boolean = useSelector(selectLoading)
    const messageToUser = useSelector(selectMessage)
    const messageTemplate: MessageTemplate = useSelector(selectMessageTemplate)
    const templateTypes: MessageTemplateType = useSelector(selectMessageTemplateTypes)
    const messageTemplateTypes = _.orderBy(templateTypes, ['isSMS', 'description'], ['asc', 'asc'])
    const templateVariables: MessageTemplateTypeVariable = useSelector(selectMessageTemplateTypeVariables)
    const messageTemplateTypeVariables = _.orderBy(templateVariables, 'description', 'asc')
    return {
        loading,
        messageTemplate,
        messageTemplateTypes,
        messageTemplateTypeVariables,
        messageToUser
    }
}