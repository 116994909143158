import * as ActionTypes from '../../actions/types';
import {
	mapMessageTemplate,
	mapMessageTemplateTypes,
	mapMessageTemplateTypeVariables
} from "../../types/messageTemplates/map";
import { fromJS } from "immutable";

const INITIAL_STATE = {
	loading: false,
	messageTemplateTypes: {},
	messageTemplateTypeVariables: {},
	messageTemplate: {},
	messageToUser: null
}

function setMessageToUser(state, action) {
	const message = action.payload
	return state
	.set('message', fromJS(message ? {
		text: message?.text, variant: message?.variant
	} : null))
}

function getMessageTemplate(state) {
	return state
	.set('loading', true)
}

function getMessageTemplateSucceeded(state, action) {
	const { payload } = action
	const mappedData = mapMessageTemplate(payload)
	return state
	.set('loading', false)
	.set('messageTemplate', fromJS(mappedData))
}

function getMessageTemplateFailed(state, action) {
	const { message } = action.payload
	return state
	.set('loading', false)
	.set('message', fromJS({
		text: message, variant: 'error'
	}))
}

function getMessageTemplateTypes(state) {
	return state
	.set('loading', true)
}

function getMessageTemplateTypesSucceeded(state, action) {
	const { payload } = action
	const mappedData = mapMessageTemplateTypes(payload)
	return state
	.set('loading', false)
	.set('messageTemplateTypes', fromJS(mappedData))
}

function getMessageTemplateTypesFailed(state, action) {
	const { message } = action.payload
	return state
	.set('loading', false)
	.set('message', fromJS({
		text: message, variant: 'error'
	}))
}

function getMessageTemplateTypeVariables(state) {
	return state
	.set('loading', true)
}

function getMessageTemplateTypeVariablesSucceeded(state, action) {
	const { payload } = action
	const mappedData = mapMessageTemplateTypeVariables(payload)
	return state
	.set('loading', false)
	.set('messageTemplateTypeVariables', fromJS(mappedData))
}

function getMessageTemplateTypeVariablesFailed(state, action) {
	const {message} = action.payload
	return state
	.set('loading', false)
	.set('message', fromJS({
		text: message, variant: 'error'
	}))
}

function updateMessageTemplate(state) {
	return state
	.set('loading', true)
}

function updateMessageTemplateSucceeded(state, action) {
	return state
	.set('loading', false)
	.set('message', fromJS({
		text: 'Message template updated successfully!', variant: 'success'
	}))
}

function updateMessageTemplateFailed(state, action) {
	const {message} = action.payload
	return state
	.set('loading', false)
	.set('message', fromJS({
		text: message, variant: 'error'
	}))
}

const messageTemplates = {
	initialState: INITIAL_STATE,
	handlers: {
		[ActionTypes.GET_MESSAGE_TEMPLATE]: getMessageTemplate,
		[ActionTypes.GET_MESSAGE_TEMPLATE_SUCCEEDED]: getMessageTemplateSucceeded,
		[ActionTypes.GET_MESSAGE_TEMPLATE_FAILED]: getMessageTemplateFailed,
		[ActionTypes.UPDATE_MESSAGE_TEMPLATE]: updateMessageTemplate,
		[ActionTypes.UPDATE_MESSAGE_TEMPLATE_SUCCEEDED]: updateMessageTemplateSucceeded,
		[ActionTypes.UPDATE_MESSAGE_TEMPLATE_FAILED]: updateMessageTemplateFailed,
		[ActionTypes.GET_MESSAGE_TEMPLATE_TYPE_VARIABLES]: getMessageTemplateTypeVariables,
		[ActionTypes.GET_MESSAGE_TEMPLATE_TYPE_VARIABLES_SUCCEEDED]: getMessageTemplateTypeVariablesSucceeded,
		[ActionTypes.GET_MESSAGE_TEMPLATE_TYPE_VARIABLES_FAILED]: getMessageTemplateTypeVariablesFailed,
		[ActionTypes.GET_MESSAGE_TEMPLATE_TYPES]: getMessageTemplateTypes,
		[ActionTypes.GET_MESSAGE_TEMPLATE_TYPES_SUCCEEDED]: getMessageTemplateTypesSucceeded,
		[ActionTypes.GET_MESSAGE_TEMPLATE_TYPES_FAILED]: getMessageTemplateTypesFailed,
		[ActionTypes.SET_MESSAGE_TO_USER]: setMessageToUser,
	}
}

export default messageTemplates
