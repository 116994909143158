// @flow
import React, {useMemo} from 'react';
import Typography from "@mui/material/Typography";
import Select from "@mui/material/Select";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import type {MessageTemplateType} from "../../../../types/messageTemplates/types";

type Props = {
    messageTemplateTypes: Array<MessageTemplateType>,
    messageTemplateTypeId: string,
    onChangeMessageTemplateType: Function,
    label: string
};

const TemplateTypeSelect = ({messageTemplateTypes, messageTemplateTypeId, onChangeMessageTemplateType, label}: Props) => {

    const selectItems = useMemo(() => {
        return messageTemplateTypes.map(type => (
            <MenuItem
                key={type.id}
                value={type.id}
            >
                {`${type.description} (τύπος ${type.id})`}
            </MenuItem>
        ))
    }, [messageTemplateTypes])

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            alignItems: 'center',
            padding: '10px 4px',
            width: '100%',
            backgroundColor: 'rgba(140, 158, 255, 0.08)'
        }}>
            <Typography variant="h6" sx={{paddingRight: 2}}>
                {label}
            </Typography>
            <Select
                labelId={'messageTemplateTypeLabel'}
                variant={'standard'}
                value={messageTemplateTypeId}
                onChange={(evt) => onChangeMessageTemplateType(evt, false)}
            >
                {selectItems}
            </Select>
        </Box>
    );
};

export default TemplateTypeSelect