//@flow
import Typography from "@mui/material/Typography";
import React from 'react';
import {useExhibition} from "../../../../hooks/exhibitions/useExhibition";
import ContentLoader from "../../../../components/general/contentLoader/contentLoader";
import ExhibitionUpdateStatus from "../../../../components/app/alerts/exhibitionUpdateStatus/exhibitionUpdateStatus";
import {Trans, useTranslation} from "react-i18next";
import {EXHIBITION} from "../../../../locales/components/namespaces";
import Breadcrumb from "../../../../components/general/breadcrumb/breadcrumb";
import useUserData from "../../../../hooks/user/useUserData";
import HomeIcon from "@mui/icons-material/Home";
import {getRouteUrl} from "../../../../helpers/getRouteUrl";
import {ROUTE_PAGE_EXHIBITION, ROUTE_PAGE_EXHIBITION_SETTINGS, ROUTE_PAGE_HOME} from "../../../../routers/routes";
import useMessageTemplate from "../../../../hooks/messageTemplates/useMessageTemplate";
import ActionBuilder from "../../../../components/form/actionBuilder/actionBuilder";
import {useExhibitionFileUpload} from "../../../../hooks/exhibitionFIles/useExhibitionFileUpload";
import {FileTypes} from "../../../../helpers/constants";
import {Alert, AlertTitle, Paper} from "@mui/material";

const InvitationMail = () => {
    const {t} = useTranslation(EXHIBITION)
    const {language} = useUserData()
    const {exhibition, files, loading, id} = useExhibition({language: 'any'})
    const {
        loadingMessageTemplate,
        messageTemplateTypeId,
        selectedMessageTemplateType,
        messageTemplate,
        messageTemplateTypes,
        messageTemplateTypeVariables,
        onSubmit,
        onChangeMessageTemplateType
    } = useMessageTemplate(id)

    const {
        getFiles,
        onUpdateAlias,
        onFilesUpload,
        onFileRemove,
    } = useExhibitionFileUpload({
        exhibitionId: id,
        typeId: FileTypes.Message,
        messageTemplateTypeId: messageTemplateTypeId
    })
    return (
        <div>
            {exhibition && messageTemplate.body ? (
                <React.Fragment>
                    <Breadcrumb>
                        <Breadcrumb.Link iconComponent={HomeIcon} to={getRouteUrl(ROUTE_PAGE_HOME)}>
                            {t('Home')}
                        </Breadcrumb.Link>
                        <Breadcrumb.Link to={getRouteUrl(ROUTE_PAGE_EXHIBITION, {id: exhibition.id})}>
                            {exhibition.title[language]}
                        </Breadcrumb.Link>
                        <Breadcrumb.Link to={getRouteUrl(ROUTE_PAGE_EXHIBITION_SETTINGS, {id: exhibition.id})}>
                            {t('Settings')}
                        </Breadcrumb.Link>
                        <Breadcrumb.Item>
                            {t('Email/SMS Text Creation')}
                        </Breadcrumb.Item>
                    </Breadcrumb>
                    <Paper sx={{mb: 1}} variant={"outlined"}>
                        <Alert severity="warning" sx={{fontSize: 14}}>
                            <AlertTitle>{t('Warning')}</AlertTitle>
                            <Trans
                                i18nKey={'sms-types-warning'}
                                ns={EXHIBITION}
                            >
                                <strong>ATTENTION: !! SMS TYPES MUST NOT INCLUDE UMLAUT DIACRITICS IN TEXT !!</strong>
                                <br/>
                                SMS provider does not allow sending text including umlaut diacritics.
                                <br/>
                                (For example if SMS text includes word 'ΛΑΪΚΗ' it will not be send to recipients.)
                                <br/>
                                <br/>
                                <strong>Copying variables between different types can lead to errors!</strong>
                                <br/>
                                For different types use only the available variables from the side list.
                                <br/>
                                (For example you cannot use 'Link Qrcode' for SMS types. It contains characters not allowed in SMS. Use 'Url Qrcode' instead from the list of available variables in SMS types.)
                            </Trans>
                        </Alert>
                    </Paper>
                    <ActionBuilder
                        loadingMessageTemplate={loadingMessageTemplate}
                        messageTemplateTypeId={messageTemplateTypeId}
                        messageTemplate={messageTemplate}
                        selectedMessageTemplateType={selectedMessageTemplateType}
                        messageTemplateTypes={messageTemplateTypes}
                        messageTemplateTypeVariables={messageTemplateTypeVariables}
                        onSubmit={onSubmit}
                        onChangeMessageTemplateType={onChangeMessageTemplateType}
                        files={{
                            en: getFiles(files.filter(f => f.type === FileTypes.Message), 'en'),
                            el: getFiles(files.filter(f => f.type === FileTypes.Message), 'el')
                        }}
                        onUpdateAlias={onUpdateAlias}
                        onFilesUpload={onFilesUpload}
                        onFileRemove={onFileRemove}
                    />
                </React.Fragment>
            ) : (
                <ContentLoader
                    loading={loading && loadingMessageTemplate}
                />
            )}
            <ExhibitionUpdateStatus/>
        </div>
    )
}

export default InvitationMail;
