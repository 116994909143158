import React, { useMemo, useState } from "react";
import * as yup from "yup";
import { FormikProvider, useFormik } from "formik";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import SaveIcon from "@mui/icons-material/Save";
import { Alert, AlertTitle, Paper, Snackbar } from "@mui/material";
import type { Exhibition, Exhibitor } from "../../../types/exhibition/types";
import useExhibitionAction, { UpdateExhibitor } from "../../../hooks/exhibitions/useExhibitionAction";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import { Trans, useTranslation } from "react-i18next";
import { EXHIBITOR_PROFILE } from "../../../locales/components/namespaces";
import { normalizeGreek } from "../../../helpers/normalizeGreek";
import CatalogHeader from "./catalogHeader";
import CatalogDataEntry from "./catalogDataEntry";
import CatalogSocial from "./catalogSocial";
import { eventStatus, formatDate } from "../../../helpers/date";
import ContentLoader from "../../../components/general/contentLoader/contentLoader";
import { useExhibition } from "../../../hooks/exhibitions/useExhibition";
import { useCountries } from "../../../hooks/countries/useCountries";
import { useHasPermission } from "../../../modules/permission/hooks/useHasPermission";
import { CatalogueLanguages, Permissions } from "../../../helpers/constants";
import { CheckOutlined, Info } from "@mui/icons-material";
import CatalogBrands from "./catalogueBrands";
import { CatalogueInfo } from "./catalogueInfo";
import EditIcon from "@mui/icons-material/Edit";
import LoadingButton from "@mui/lab/LoadingButton";
import { enqueueSnackbar } from "notistack";
import ConfirmDialog from "../../../components/app/dialogs/confirmDialog/confirmDialog";
import CatalogueWordPreview from "./catalogueWordPreview";
import dayjs from "dayjs";
import useUserData from "../../../hooks/user/useUserData";

type Props = {
	exhibitor: Exhibitor, exhibitionId: string,
}

type InnerProps = Props & { exhibition: Exhibition }

const Section = ({ disabled, children }) => {
	return (<Paper sx={{ p: 2, mb: 1 }} variant={"outlined"}>
		{children}
	</Paper>)
}

export const showNoValueIfDisabled = (value, disabled) => disabled ? (!value || value === "") ? '-' : value : value

const CatalogInner = (props: InnerProps) => {
	const { t } = useTranslation(EXHIBITOR_PROFILE)
	const { dateFormat } = useUserData()
	const {
		exhibitor,
		exhibitionId,
		exhibition,
		phoneCodesEl,
		phoneCodesEn,
		countriesEl,
		countriesEn,
		handleShowCatalogueInfo,
		loading
	} = props
	const {
		updateExhibitorProfile,
		exhibitorCatalogueConfirmReset,
		exhibitorCatalogueConfirm,
		setExhibitorEditMode
	} = useExhibitionAction()
	const isOrganizer = useHasPermission([Permissions.BeOrganizer], { shouldView: true })
	const isExhibitor = useHasPermission([Permissions.BeExhibitor], { shouldView: true })
	const [openConfirm, setOpenConfirm] = useState(false)
	const onSubmit = (values) => {
		updateExhibitorProfile(exhibitor.id, exhibitionId, values, UpdateExhibitor.Catalog)
		// debugger
		// .then(() => {
		// 	debugger
		// 	if (isExhibitor) exhibitorCatalogueConfirm(exhibitionId, exhibitor.id)
		// })
		setOpenConfirm(false)
	}
	
	const editMode = useMemo(() => (exhibitor?.editMode), [exhibitor?.editMode]);
	const validationSchema = yup.object({
		'brand': yup.object({
			'el': exhibition.catalogueLanguage === CatalogueLanguages.EN ? yup.string() : yup.string().required(t('required')),
			'en': exhibition.catalogueLanguage === CatalogueLanguages.EN || exhibition.catalogueLanguage === CatalogueLanguages.ALL ? yup.string().matches(/^([A-Za-z^!@#$%&*()_+\-\\|{}[\].,<>?'":;/`=\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s\w]*)$/gi, t('Only latin and special characters allowed.')).required(t('required')) : yup.string()
		}),
		'social': yup.object({
			'email': yup.string().required(t('required')),
		}),
		'address1': yup.object({
			'country': yup.object({
				'en': yup.mixed().test(
					'required', t('required'), function (value) {
						return exhibition.catalogueLanguage !== CatalogueLanguages.EL ? value && value?.id : true
					}
				),
				'el': yup.mixed().test(
					'required', t('required'), function (value) {
						return exhibition.catalogueLanguage !== CatalogueLanguages.EN ? value && value?.id : true
					}
				),
			}),
			'phone': yup.object({
				'en': yup.mixed().test(
					'required', t('required'), function (value) {
						return exhibition.catalogueLanguage !== CatalogueLanguages.EL ? value : true
					}
				),
				'el': yup.mixed().test(
					'required', t('required'), function (value) {
						return exhibition.catalogueLanguage !== CatalogueLanguages.EN ? value : true
					}
				),
			}),
		}),
		brands0: yup.string().test(
			'not same as company name',
			t('The Brand must not be the same as the Company Name.'),
			function (value) {
				return (value !== this.parent['brand']?.en && value !== this.parent['brand']?.el) || !value
			}),
		brands1: yup.string().test(
			'not same as company name',
			t('The Brand must not be the same as the Company Name.'),
			function (value) {
				return (value !== this.parent['brand']?.en && value !== this.parent['brand']?.el) || !value
			}),
		brands2: yup.string().test(
			'not same as company name',
			t('The Brand must not be the same as the Company Name.'),
			function (value) {
				return (value !== this.parent['brand']?.en && value !== this.parent['brand']?.el) || !value
			}),
		brands3: yup.string().test(
			'not same as company name',
			t('The Brand must not be the same as the Company Name.'),
			function (value) {
				return (value !== this.parent['brand']?.en && value !== this.parent['brand']?.el) || !value
			}),
		brands4: yup.string().test(
			'not same as company name',
			t('The Brand must not be the same as the Company Name.'),
			function (value) {
				return (value !== this.parent['brand']?.en && value !== this.parent['brand']?.el) || !value
			}),
		brands5: yup.string().test(
			'not same as company name',
			t('The Brand must not be the same as the Company Name.'),
			function (value) {
				return (value !== this.parent['brand']?.en && value !== this.parent['brand']?.el) || !value
			}),
		brands6: yup.string().test(
			'not same as company name',
			t('The Brand must not be the same as the Company Name.'),
			function (value) {
				return (value !== this.parent['brand']?.en && value !== this.parent['brand']?.el) || !value
			}),
		brands7: yup.string().test(
			'not same as company name',
			t('The Brand must not be the same as the Company Name.'),
			function (value) {
				return (value !== this.parent['brand']?.en && value !== this.parent['brand']?.el) || !value
			}),
		brands8: yup.string().test(
			'not same as company name',
			t('The Brand must not be the same as the Company Name.'),
			function (value) {
				return (value !== this.parent['brand']?.en && value !== this.parent['brand']?.el) || !value
			}),
		brands9: yup.string().test(
			'not same as company name',
			t('The Brand must not be the same as the Company Name.'),
			function (value) {
				return (value !== this.parent['brand']?.en && value !== this.parent['brand']?.el) || !value
			})
	})
	
	const formik = useFormik({
		initialValues: {
			brand: {
				en: exhibitor.catalog.brand.en,
				el: exhibitor.catalog.brand.el,
			},
			profile: {
				en: exhibitor.catalog.profile.en,
				el: exhibitor.catalog.profile.el,
			},
			address1: {
				...exhibitor.address1,
				country: {
					el: typeof exhibitor.address1.country.el === 'string' && exhibitor.address1.country.el ? countriesEl.find(f => f.id === exhibitor.address1.country.el) : '',
					en: typeof exhibitor.address1.country.en === 'string' && exhibitor.address1.country.en ? countriesEn.find(f => f.id === exhibitor.address1.country.en) : '',
				},
				phoneCode: {
					el: typeof exhibitor.address1.phoneCode.el === 'string' && exhibitor.address1.phoneCode.el ? phoneCodesEl.find(f => f.id === exhibitor.address1.phoneCode.el) : '',
					en: typeof exhibitor.address1.phoneCode.en === 'string' && exhibitor.address1.phoneCode.en ? phoneCodesEn.find(f => f.id === exhibitor.address1.phoneCode.en) : '',
				}
			},
			address2: {
				...exhibitor.address2,
				country: {
					el: typeof exhibitor.address2.country.el === 'string' && exhibitor.address2.country.el ? countriesEl.find(f => f.id === exhibitor.address2.country.el) : '',
					en: typeof exhibitor.address2.country.en === 'string' && exhibitor.address2.country.en ? countriesEn.find(f => f.id === exhibitor.address2.country.en) : '',
				},
				phoneCode: {
					el: typeof exhibitor.address2.phoneCode.el === 'string' && exhibitor.address2.phoneCode.el ? phoneCodesEl.find(f => f.id === exhibitor.address2.phoneCode.el) : '',
					en: typeof exhibitor.address2.phoneCode.en === 'string' && exhibitor.address2.phoneCode.en ? phoneCodesEn.find(f => f.id === exhibitor.address2.phoneCode.en) : ''
				}
			},
			social: {
				website: exhibitor.website,
				email: exhibitor.emailCatalogue,
				fb: exhibitor.social.fb,
				instagram: exhibitor.social.instagram,
				linkedIn: exhibitor.social.linkedIn
			},
			brands0: exhibitor.brands[0] ? exhibitor.brands[0] : '',
			brands1: exhibitor.brands[1] ? exhibitor.brands[1] : '',
			brands2: exhibitor.brands[2] ? exhibitor.brands[2] : '',
			brands3: exhibitor.brands[3] ? exhibitor.brands[3] : '',
			brands4: exhibitor.brands[4] ? exhibitor.brands[4] : '',
			brands5: exhibitor.brands[5] ? exhibitor.brands[5] : '',
			brands6: exhibitor.brands[6] ? exhibitor.brands[6] : '',
			brands7: exhibitor.brands[7] ? exhibitor.brands[7] : '',
			brands8: exhibitor.brands[8] ? exhibitor.brands[8] : '',
			brands9: exhibitor.brands[9] ? exhibitor.brands[9] : '',
			kioskSign: exhibitor.kioskSign || ''
		},
		validationSchema: validationSchema,
		onSubmit: onSubmit,
	})
	const { catalogLocked, daysForCatalogSubmission, dateFrom, dateTo } = exhibition
	const status = eventStatus(dateFrom, dateTo)
	const dayBeforeCatalogSubmission = dayjs(dateFrom).subtract(daysForCatalogSubmission, 'd')
	const canFillCatalogIsBefore = dayjs().isSame(dayBeforeCatalogSubmission, 'day')
	const canFillCatalogIsSame = dayjs().isBefore(dayBeforeCatalogSubmission, 'day')
	const canFillCatalog = canFillCatalogIsBefore || canFillCatalogIsSame || exhibitor.openExhibitorCatalogue
	const catalogDisabled = (isOrganizer && catalogLocked) || (!isOrganizer && (catalogLocked || status !== eventStatus.status.UPCOMING || !canFillCatalog))
	const inputVariant = 'standard'
	
	const confirmSubmit = (evt) => {
		formik.validateForm()
		.then((response) => {
				if (Object.keys(response).length === 0) {
					setOpenConfirm(true)
				} else {
					enqueueSnackbar(t('Please fill all required fields to continue!'), {
						variant: 'warning',
					})
				}
			}
		)
	}
	
	const confirmCatalogueData = (evt) => {
		formik.validateForm()
		.then((response) => {
				if (Object.keys(response).length === 0) {
					if (isExhibitor) {
						exhibitorCatalogueConfirm(exhibitionId, exhibitor.id)
					} else {
						enqueueSnackbar(t('Catalogue confirmation can only be done from exhibitors!'), {
							variant: 'warning',
						})
					}
				} else {
					enqueueSnackbar(t('Please fill all required fields to continue!'), {
						variant: 'warning',
					})
				}
			}
		)
	}
	
	return (
		<FormikProvider value={formik}>
			<Box component="form" noValidate>
				<Box sx={{
					'& .Mui-disabled': {
						color: 'text.primary',
						WebkitTextFillColor: 'initial!important',
						// "-webkit-text-fill-color": 'initial!important',
					},
					'& .MuiInputLabel-root.Mui-disabled': {
						color: 'text.primary',
						WebkitTextFillColor: 'initial!important',
						// "-webkit-text-fill-color": 'initial!important',
					},
				}}>
					{!catalogLocked ? (<React.Fragment>
						{status !== eventStatus.status.EXPIRED && !isNaN(daysForCatalogSubmission) && daysForCatalogSubmission > 0 && (
							<React.Fragment>
								{canFillCatalog ? (<Alert severity="info">
									<AlertTitle>{t('Info')}</AlertTitle>
									<Trans
										i18nKey={'catalog_submit_warning'}
										ns={EXHIBITOR_PROFILE}
										values={{
											date: formatDate(dayBeforeCatalogSubmission, dateFormat)
										}}
										defaults={"You can submit your catalogue information up until <bold>{{date}}</bold>."}
										components={{ bold: <strong/> }}
									/>
								</Alert>) : (<Alert severity="warning">
									<AlertTitle>{t('Warning')}</AlertTitle>
									<Trans
										i18nKey={'catalog_submit_ended_warning'}
										ns={EXHIBITOR_PROFILE}
										values={{
											email: "info@rota.gr"
										}}
										defaults={"The period in which you can edit your catalogue information has ended."}
										components={{ bold: <strong/>, a: <a/> }}
									/>
								</Alert>)}
							</React.Fragment>)}
					</React.Fragment>) : (
						<Alert severity={"info"}>
							<AlertTitle>{t('Info')}</AlertTitle>
							<Trans
								i18nKey={'catalog_submit_disabled'}
								ns={EXHIBITOR_PROFILE}
								values={{
									email: "support@rota.gr",
									date: formatDate(dayBeforeCatalogSubmission, formatDate.formatTypes.DATE_ONLY)
								}}
								defaults={"Catalogue editing is disabled for this event. You can only view your catalogue information. <br/> <strong>ΑΤΤΕΝΤΙΟΝ:</strong> Changes in catalogue are possible after contacting us. <br/> Please contact us at <a href='mailto:{{email}}'>{{email}}</a> if you need help."}
								components={{ bold: <strong/>, a: <a/> }}
							/>
						</Alert>)}
					{!catalogDisabled && (<Toolbar disableGutters variant={"dense"}>
						<Box sx={{ flexGrow: 1 }}/>
						<Stack direction="row" spacing={1} width={'100%'}>
							{editMode && (
								<LoadingButton
									size={"small"}
									onClick={confirmSubmit}
									endIcon={<SaveIcon/>}
									loading={loading}
									loadingPosition="end"
									variant="contained"
									style={{ minWidth: 'unset' }}
									color={'success'}
								>
									{normalizeGreek(t('Save'))}
								</LoadingButton>
							)}
							{!editMode && (
								<>
									<Button
										size={"small"}
										startIcon={<EditIcon/>}
										variant="contained"
										onClick={() => {
											setExhibitorEditMode(exhibitor.id, true)
										}}
										style={{ minWidth: 'unset' }}
										disabled={catalogDisabled}
									>
										{normalizeGreek(t("Edit"))}
									</Button>
									<Button
										variant={"contained"}
										size={"small"}
										startIcon={<CheckOutlined/>}
										color={'info'}
										onClick={(evt) => {
											confirmCatalogueData()
										}}
									>
										{normalizeGreek(t("Confirm Catalogue Data"))}
									</Button>
								</>
							)}
							<Button
								variant={"contained"}
								size={"small"}
								startIcon={<Info/>}
								color="warning"
								style={{
									marginLeft: 'auto',
									backgroundColor: '#212121',
									minWidth: 'unset'
								}}
								onClick={(evt) => handleShowCatalogueInfo(true)}
							>
								{normalizeGreek(t("Catalogue Entry Instructions"))}
							</Button>
						</Stack>
					</Toolbar>)}
					<Divider light sx={{ mb: 1 }}/>
					<Grid container spacing={2}>
						<Grid item xs={12} md={3} order={{ md: 2 }}>
							<Section>
								<CatalogHeader
									exhibition={exhibition}
									exhibitor={exhibitor}
									disabled={catalogDisabled || !editMode}
									formik={formik}
									inputVariant={inputVariant}
								/>
							</Section>
						</Grid>
						<Grid item xs={12} md={9} order={{ md: 1 }}>
							<Grid container spacing={2}>
								{(exhibition.catalogueLanguage === CatalogueLanguages.EL || exhibition.catalogueLanguage === CatalogueLanguages.ALL) && (
									<Grid item xs={12}
										  md={exhibition.catalogueLanguage !== CatalogueLanguages.ALL ? 12 : 6}>
										<Section disabled={catalogDisabled || !editMode}>
											<CatalogDataEntry
												catalogueProfileLength={exhibitor.catalogueProfileLength ? exhibitor.catalogueProfileLength : exhibition.catalogueProfileLength}
												catalogueLanguage={exhibition.catalogueLanguage}
												disabled={catalogDisabled || !editMode}
												inputVariant={inputVariant}
												language={'el'}
												formik={formik}
												countriesEl={countriesEl}
												countriesEn={countriesEn}
												phoneCodesEl={phoneCodesEl}
												phoneCodesEn={phoneCodesEn}
											/>
										</Section>
									</Grid>
								)}
								{(exhibition.catalogueLanguage === CatalogueLanguages.EN || exhibition.catalogueLanguage === CatalogueLanguages.ALL) && (
									<Grid item xs={12}
										  md={exhibition.catalogueLanguage !== CatalogueLanguages.ALL ? 12 : 6}>
										<Section disabled={catalogDisabled || !editMode}>
											<CatalogDataEntry
												catalogueProfileLength={exhibitor.catalogueProfileLength ? exhibitor.catalogueProfileLength : exhibition.catalogueProfileLength}
												catalogueLanguage={exhibition.catalogueLanguage}
												disabled={catalogDisabled || !editMode}
												inputVariant={inputVariant}
												language={'en'}
												formik={formik}
												countriesEl={countriesEl}
												countriesEn={countriesEn}
												phoneCodesEl={phoneCodesEl}
												phoneCodesEn={phoneCodesEn}
											/>
										</Section>
									</Grid>
								)}
							</Grid>
							<Section disabled={catalogDisabled || !editMode}>
								<CatalogSocial
									disabled={catalogDisabled || !editMode}
									inputVariant={inputVariant}
									formik={formik}
								/>
							</Section>
							{exhibition.hasExhibitorBrands && <Section disabled={catalogDisabled || !editMode}>
								<CatalogBrands
									disabled={catalogDisabled || !editMode}
									inputVariant={inputVariant}
									formik={formik}
								/>
							</Section>}
						</Grid>
					</Grid>
				</Box>
			</Box>
			<ConfirmDialog
				onYes={formik.submitForm}
				onNo={() => setOpenConfirm(false)}
				handleDialog={(newValue) => setOpenConfirm(newValue)}
				title={t('Confirm Catalogue Submission')}
				open={Boolean(openConfirm)}
				yesBtnText={t('YES')}
				noBtnText={t('NO')}
				maxWidth={'md'}
			>
				<Box>
					{t('You may have empty optional fields in your catalogue.')}
					<br/><br/>
					{t('Do you still wish to submit your catalogue?')}
					<br/><br/>
				</Box>
				<CatalogueWordPreview values={formik.values} exhibitor={exhibitor} exhibition={exhibition}/>
			</ConfirmDialog>
		</FormikProvider>)
}

const Catalog = (props: Props) => {
	const { t } = useTranslation(EXHIBITOR_PROFILE)
	const { exhibition, loading } = useExhibition()
	const [openSuccess, setOpenSuccess] = useState(false)
	const [showCatalogueInfo, setShowCatalogueInfo] = useState(true)
	const handleShowCatalogueInfo = (show) => setShowCatalogueInfo(show)
	const {
		loading: countriesLoading,
		countriesEl,
		countriesEn,
		phoneCodesEn,
		phoneCodesEl
	} = useCountries()
	const handleCloseAlert = () => setOpenSuccess(false)
	return (<React.Fragment>
		{exhibition && !countriesLoading ? (
			<>
				<CatalogInner
					{...props}
					exhibition={exhibition}
					countriesEl={countriesEl}
					countriesEn={countriesEn}
					phoneCodesEl={phoneCodesEl}
					phoneCodesEn={phoneCodesEn}
					handleShowCatalogueInfo={handleShowCatalogueInfo}
				/>
				<CatalogueInfo
					{...props}
					open={showCatalogueInfo}
					exhibition={exhibition}
					onClose={() => setShowCatalogueInfo(false)}
				/>
			</>
		) : (<ContentLoader
			loading={loading}
		/>)}
		<Snackbar
			open={openSuccess}
			anchorOrigin={{
				vertical: 'top', horizontal: 'right',
			}}
			autoHideDuration={6000}
			onClose={handleCloseAlert}>
			<Alert
				onClose={handleCloseAlert}
				severity="success"
				sx={{ width: '100%' }}
			>
				{t('Saved successfully!')}
			</Alert>
		</Snackbar>
	</React.Fragment>)
}

export default Catalog